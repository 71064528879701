import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { postLogin } from "../../../helpers/backend_helper";
import { setAuthorization } from "../../../helpers/api_helper";

function* loginUser({ payload: { user, history } }) {
  try {
      const response = yield call(postLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response.user));
      localStorage.setItem("accessToken", JSON.stringify(response.jwt));
      setAuthorization(response.jwt);
      yield put(loginSuccess(response));
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("accessToken");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
