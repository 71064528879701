import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PACK, FETCH_PACKS } from "./actionTypes"
import { setPacks, packSuccess, packError } from "./actions"

//Include Both Helper File with needed methods
import { getPacks, updatePack } from "../../helpers/backend_helper"


function* fetchPacks({ payload: data }) {
  try {
    const response = yield call(getPacks, data)
    yield put(setPacks(response))
    yield put(packSuccess(response))
  } catch (error) {
    yield put(packError(error))
  }
}

function* editPack({ payload: data }) {
  try {
     yield call(updatePack, data)
    const response = yield call(getPacks, data)
    yield put(setPacks(response))
    yield put(packSuccess(response))
  } catch (error) {
    yield put(packError(error))
  }
}

export function* watchPack() {
  yield takeEvery(FETCH_PACKS, fetchPacks)
  yield takeEvery(EDIT_PACK, editPack)
}

function* PackSaga() {
  yield all([fork(watchPack)])
}

export default PackSaga;
