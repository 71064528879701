import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { addPromotion, editPromotion, fetchMenus, fetchResto } from "../../store/actions";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "flatpickr/dist/themes/material_green.css";
import { getLoggedInUser } from "../../helpers/backend_helper";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Details = ({ info, onChange }) => {
    const [name, setName] = useState(null);
    const [type, setType] = useState(0);
    const [menu, setMenu] = useState(null);
    const [sDate, setSDate] = useState(null);
    const [eDate, setEDate] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [shift, setShift] = useState(null);
    const [covers, setCovers] = useState(null);
    const [maxCovers, setMaxCovers] = useState(null);
    const [coversFrom, setCoversFrom] = useState(null);
    const [coversTo, setCoversTo] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [isCovers, setIsCovers] = useState(false);
    const [isMenu, setIsMenu] = useState(false);
    const [isShift, setIsShift] = useState(false);
    const [days, setDays] = useState([]);
    const promotion = useSelector(state => state.promotion?.promotion)
    const menus = useSelector(state => state.menu?.data || [])
    const shifts = useSelector(state => state.restaurant.resto?.t_shift);
    const user = getLoggedInUser();

    const types = [
        // { name: 'Percentage', value: 'P' },
        { name: 'MIAMS', value: 1 },
    ]
    const daysO = [
        { name: 'Monday', value: 0 },
        { name: 'Tuesday', value: 1 },
        { name: 'Wednesday', value: 2 },
        { name: 'Tuesday', value: 3 },
        { name: 'Friday', value: 4 },
        { name: 'Saturday', value: 5 },
        { name: 'Sunday', value: 6 },
    ]

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchResto())
        dispatch(fetchMenus())
    }, [])

    useEffect(() => {
        setName(info?.name)
        setDiscount(info?.promo_value || 0)
        if (info?.menu) {
            setIsMenu(true)
            setMenu(info.menu)
        }
        if (info?.shifts && info.shifts.length > 0) {
            setIsShift(true)
            const shs = shifts?.filter(el => info.shifts.includes(el.id))
            setShift(shs)
        }
        if (info?.couvert) {
            setIsCovers(true)
            setCovers(info.couvert)
            setMaxCovers(info.couvert_max)
        }
        setType(info?.accept_miam)
        setSDate(info?.date_from)
        setEDate(info?.date_to)
        setFrom(info?.promo_start_hour)
        setTo(info?.promo_end_hour)
        if (info?.days) {
            const rdays = info.days.split(',')
            rdays.forEach((el, index) => el === '1' && days.push(index))
        }
    }, [info])

    const setTime = (date, fromOrTo) => {
        if (!date) {
            if (fromOrTo === 'from') return setFrom(null);
            if (fromOrTo === 'to') return setTo(null);
        }
        if (date.length > 0) {
          // Format to "HH:MM" using JavaScript's Date methods
          const hours = date[0].getHours().toString().padStart(2, '0');
          const minutes = date[0].getMinutes().toString().padStart(2, '0');
          if (fromOrTo === 'from') return setFrom(`${hours}:${minutes}`);
          if (fromOrTo === 'to') return setTo(`${hours}:${minutes}`);
        }
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        console.log(value, checked)
        if (!checked) {
          // Add selected option
          setDays([...days, parseInt(value)]);
        } else {
          // Remove unselected option
          setDays(days.filter((option) => option !== parseInt(value)));
        }
    };

    const submitChanges = () => {
        const array = daysO.map(el => (days.includes(el.value) ? '1' : '0'))
        const payload = {
            name,
            accept_miam: type || 0,
            // menu: menu && menu.id,
            date_from: sDate,
            date_to: eDate,
            promo_value: discount && discount.toString(),
            promo_value_type: ((type === 1 && discount ? 'PM' : 'P') || 'M' ),
            // shifts: shifts?.map(el => el.id),
            couvert: covers ? covers.toString() : '0',
            couvert_max: maxCovers ? maxCovers.toString() : '0',
            promo_start_hour: from,
            promo_end_hour: to,
            days: array && array.join(','),
            resto: user.resto,
        }
        console.log(payload)
        if (info) payload.id = info.id
        if (info) {
            dispatch(editPromotion(payload))
            return onChange()
        }
        dispatch(addPromotion(payload))
        return onChange()

    }

    const changeSdate = (date) => {
    return setSDate(date)
    // Format the date using toISOString to get yyyy-mm-dd format
    // const formattedDate = date[0].toISOString().slice(0, 10); 
    // setSDate(formattedDate);
    };

    const changeEdate = (date) => {
        return setEDate(date)
        // Format the date using toISOString to get yyyy-mm-dd format
        // const formattedDate = date[0].toISOString().slice(0, 10); 
        // setEDate(formattedDate);
        };

    return (
        <>
            <form onSubmit={(e) => {
                    e.preventDefault();
                    submitChanges()
                    return false;
                }}
            >
                <Row>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="name">{t('Name')}</Label>
                            <Input value={name} onChange={e => setName(e.target.value)} type="text" name="name" />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <Label for="menu">{t('Discount').toLocaleLowerCase()}</Label>
                        <div className="d-flex align-items-center flex-wrap mt-1">
                          {[0, 5, 10, 15, 20, 30, 40, 50].map((el, index) => (
                            <FormGroup check className="me-2" key={el}>
                              <Label check>
                                <Input
                                  type="radio"
                                  checked={discount === el}
                                  onClick={() => setDiscount(el)}
                                  value={el} 
                                  name="discountOptions"
                                />{' '}
                                {el}% 
                              </Label>
                            </FormGroup>
                          ))}
                            <span className="mx-2">|</span>
                            <FormGroup check className="">
                                <Label check>
                                    <Input type="checkbox" checked={type === 1} onClick={() => setType(type ? 0 : 1)} />{' '}
                                    {'MIAMS'}
                                </Label>
                            </FormGroup>
                        </div>
                    </Col>
                    <Col md={12} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="covers">{t('Covers')}</Label>
                            <div className="d-flex">
                                <Input className="me-4" min={1} placeholder="From" value={covers} onChange={e => setCovers(e.target.value)} type="number" name="covers" />
                                <Input placeholder="To" value={maxCovers} onChange={e => setMaxCovers(e.target.value)} type="number" name="max-covers" />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={6} className="mb-2">
                        <Label for="menu" className="d-block">{t('Start date')}</Label>
                        <Flatpickr
                            value={sDate}
                            onChange={changeSdate}
                            className="me-2"
                            placeholder="Date"
                            style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5, width: '100%' }}
                        />
                    </Col>
                    <Col md={6} className="mb-2">
                        <Label for="menu" className="d-block">{t('End date')}</Label>
                        <Flatpickr
                            value={eDate}
                            options={{
                                minDate: sDate,
                            }}
                            onChange={changeEdate}
                            className="me-2"
                            placeholder="Date"
                            style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5, width: '100%' }}
                        />
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="from" className="d-block">{t('From')}</Label>
                            <div className='position-relative flex-grow-1'>
                              <Flatpickr
                                value={from}
                                onChange={val => setTime(val, 'from')}
                                className="me-2"
                                placeholder='From'
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'H:i', // Format to only show hour and minute
                                  time_24hr: true // Use 24-hour time format
                                }}
                                style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                              />
                              {from && (
                                <button
                                  type="button"
                                  onClick={() => setTime(null, 'from')}
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="to" className="d-block">{t('To')}</Label>
                            <div className='position-relative flex-grow-1'>
                              <Flatpickr
                                value={to}
                                onChange={val => setTime(val, 'to')}
                                className="me-2"
                                placeholder='From'
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'H:i', // Format to only show hour and minute
                                  time_24hr: true // Use 24-hour time format
                                }}
                                style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                              />
                              {to && (
                                <button
                                  type="button"
                                  onClick={() => setTime(null, 'to')}
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={12} className="border-bottom mb-2">
                        <Label for="days" className="d-block">{t('Days')}</Label>
                        <div className="d-flex align-items-center flex-wrap mt-1">
                            {daysO.map((el, index) => (
                                <FormGroup check className="me-2" key={index}>
                                    <Label check>
                                        <Input type="checkbox" checked={days.includes(el.value)} onClick={e => handleCheckboxChange(e)} value={el.value} name={el.value} />{' '}
                                        {el.name}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mt-4">{t('Save changes')}</button>
                </div>
            </form>
        </>
    )
}

export default withRouter(withTranslation()(Details));
