import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import Details from './details';
import { fetchInvoice, fetchInvoices } from '../../store/actions';
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Invoice = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const invoices = useSelector(state => state.invoice?.data || [])
  const loading = useSelector(state => state.table?.loading || false)
  const [fInvoices, setFInvoices] = useState([]);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);

  const toggleModal = data => {
    if (data) dispatch(fetchInvoice(data.id))
    setCurrent(data || null)
    return setModal(!modal);
  }

  useEffect(() => {
    dispatch(fetchInvoices())
  }, [])

  useEffect(() => {
    if (invoices) {
      if (q) {
        const filteredInvoices = fInvoices.filter(el => {
          const fullName = `${el.id} ${el.total}`.toString().toLowerCase();
          return fullName.includes(q.toLowerCase())
        })
        setFInvoices(filteredInvoices) 
      }
      else setFInvoices(invoices)
    }
    else setFInvoices(invoices)
  }, [invoices, q])

  const resolveStatusTitle = status => {
    if (status === 0) return t('Unpaid')
    if (status === 1) return t('Paid')
    if (status === 2) return t('Paid with MIAMS')
    return status
  }

  const resolveStatusBg = status => {
    if (status === 0) return '#fcb92c' // Unpaid
    if (status === 1) return '#0ac074' // Paid
    if (status === 2) return '#4aa3ff' // Paid with MIAMS
    return '#74788d'
  }

  const resolveDate = isoDate => {
    if (!isoDate) return null; // Handle invalid input
    const date = new Date(isoDate);
  
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    return `${day}-${month}-${year}`;
  }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>{t('Invoice')} #</span>,
            selector: row => row.ref,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Billing date')}</span>,
            selector: row => resolveDate(row.invoice_date),
            sortable: true
        },
        {
            name: <span className='text-danger font-weight-bold fs-13'>{t('Due date')}</span>,
            selector: row => resolveDate(row.due_date),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Total</span>,
            selector: row => `${parseFloat(row.total).toFixed(2)} Dhs`,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: true,
            selector: (cell) => <span className="badge p-2 px-4 fs-13" style={{ backgroundColor: resolveStatusBg(cell.status) }}> {resolveStatusTitle(cell.status)} </span>,
        },
        {
            name: <span className='font-weight-bold fs-13'>Actions</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <Link to={`/invoices/details?id=${cell.id}`} className="btn btn-sm btn-primary me-2 shadow text-nowrap">{t('View invoice')}</Link>
                        {/* <button onClick={() => toggleModal1(cell)} className="btn btn-sm btn-danger me-2 shadow text-nowrap px-3"><i className="ri-delete-bin-line align-bottom"></i></button> */}
                    </>
                );
            },
        },       
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title={t("My restaurant")} breadcrumbItem={t("Invoices")} />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-end">
                      <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                          <div className="position-relative">
                          <Input
                            value={q}
                            onChange={e => setQ(e.target.value)}
                            type="text"
                            debounce={600}
                            placeholder={t("Search")}
                            name="q" />
                          <span className="ri-search-line"></span>
                          </div>
                      </form>
                  </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={fInvoices}
                pagination
                // paginationServer
                // paginationDefaultPage={page}
                // paginationPerPage={perPage}
                // onChangePage={setPage}
                // onChangeRowsPerPage={setPerPage}
                progressPending={loading}
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {t('Invoice')}
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Details info={current} />
                </div>
            </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default withRouter(withTranslation()(Invoice));