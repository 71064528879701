import React, { useEffect, useState } from "react";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserPanel from "./UserPanel";
import Approval from "./Approval";
import { useDispatch } from "react-redux";
import { getDashboard } from "../../helpers/backend_helper";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Dashboard = () => {

  const [data, setData] = useState({});

  const fetchDashboard = async () => {
    try {
      const response = await getDashboard()
      setData(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchDashboard()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Mymeal" breadcrumbItem={t('Dashboard')} />
          {/* User Panel Charts */}
          <UserPanel data={data} />
          {/* <OverView /> */}
          <Approval data={data} refresh={() => fetchDashboard()} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Dashboard));

