import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { editResHours } from '../../store/actions';

const ResHours = ({ data }) => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (!from) setFrom(data.from)
    if (!to) setTo(data.to)
  }, [data])

  const setTime = (date, fromOrTo) => {
    if (!date) {
        // if (fromOrTo === 'from') return setFrom(null);
        // if (fromOrTo === 'to') return setTo(null);
    }
    if (date.length > 0) {
      const hours = date[0].getHours().toString().padStart(2, '0');
      const minutes = date[0].getMinutes().toString().padStart(2, '0');
      if (fromOrTo === 'from') return setFrom(`${hours}:${minutes}`);
      if (fromOrTo === 'to') return setTo(`${hours}:${minutes}`);
    }
  };

  const save = () => {
    dispatch(editResHours({ id: data.id, hour_begin: from, hour_end: to }))
  }
  
    return (
        <>
            <Row>
            <Col>
                <div className='position-relative flex-grow-1'>
                    <Flatpickr
                      value={from}
                      onChange={val => setTime(val, 'from')}
                      className="me-2"
                      placeholder='From'
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i', // Format to only show hour and minute
                        time_24hr: true // Use 24-hour time format
                      }}
                      style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                    />
                    {from && (
                      <button
                        type="button"
                        onClick={() => setTime(null, 'from')} // Clear the value
                        style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                        title="Clear"
                      >
                        ×
                      </button>
                    )}
                  </div>
              </Col>
              <Col>
                <div className='position-relative flex-grow-1'>
                  <Flatpickr
                    value={to}
                    onChange={val => setTime(val, 'to')}
                    placeholder='To'
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i', // Format to only show hour and minute
                      time_24hr: true // Use 24-hour time format
                    }}
                    style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                  />
                  {to && (
                    <button
                      type="button"
                      onClick={() => setTime(null, 'to')} // Clear the value
                      style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                      title="Clear"
                    >
                      ×
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          <div className="d-flex justify-content-end align-items-end mt-4">
            <button
              type="button"
              className='btn btn-primary'
              onClick={() => save()} // Clear the value
            >
              {t('Save changes')}
            </button>
          </div>
        </>
    );
};

export default withRouter(withTranslation()(ResHours));