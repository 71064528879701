import { ADD_CATEGORY, ADD_ITEM, ADD_MENU, DELETE_CATEGORY, DELETE_ITEM, DELETE_MENU, EDIT_CATEGORY, EDIT_ITEM, EDIT_MENU, FETCH_MENU, FETCH_MENUS, MENU_ERROR, MENU_SUCCESS, MOVE_CATEGORY, MOVE_ITEM, MOVE_MENU, SET_MENU, SET_MENUS } from "./actionTypes"

export const fetchMenus = data => {
  return {
    type: FETCH_MENUS,
    payload: data
  }
}

export const setMenus = data => {
  return {
    type: SET_MENUS,
    payload: data,
  }
}

export const fetchMenu = data => {
  return {
    type: FETCH_MENU,
    payload: data
  }
}

export const setMenu = data => {
  return {
    type: SET_MENU,
    payload: data,
  }
}

export const addMenu = data => {
  return {
    type: ADD_MENU,
    payload: data,
  }
}

export const editMenu = data => {
  return {
    type: EDIT_MENU,
    payload: data,
  }
}

export const moveMenu = data => {
  return {
    type: MOVE_MENU,
    payload: data,
  }
}

export const removeMenu = id => {
  return {
    type: DELETE_MENU,
    payload: id,
  }
}
export const addCategory = data => {
  return {
    type: ADD_CATEGORY,
    payload: data,
  }
}

export const editCategory = data => {
  return {
    type: EDIT_CATEGORY,
    payload: data,
  }
}

export const moveCategory = data => {
  return {
    type: MOVE_CATEGORY,
    payload: data,
  }
}

export const removeCategory = id => {
  return {
    type: DELETE_CATEGORY,
    payload: id,
  }
}
export const addItem = data => {
  return {
    type: ADD_ITEM,
    payload: data,
  }
}

export const editItem = data => {
  return {
    type: EDIT_ITEM,
    payload: data,
  }
}

export const moveItem = data => {
  return {
    type: MOVE_ITEM,
    payload: data,
  }
}

export const removeItem = id => {
  return {
    type: DELETE_ITEM,
    payload: id,
  }
}

export const menuSuccess = msg => {
  return {
    type: MENU_SUCCESS,
    payload: msg,
  }
}

export const menuError = error => {
  return {
    type: MENU_ERROR,
    payload: error,
  }
}
