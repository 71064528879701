import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Col, FormGroup, Input, Label, Modal, Row, UncontrolledAccordion } from 'reactstrap';
import { addShift, editShift, removeShift } from '../../store/actions';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const Shifts = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [q, setQ] = useState('');
  const [shifts, setShifts] = useState([]);

  const [schedule, setSchedule] = useState([
    {
      day: 'Monday',
      shifts: [
        { from: '8:00', to: '11:30' },
        { from: '12:00', to: '16:30' },
        { from: '17:00', to: '00:00' },
      ]
    },
    {
      day: 'Tuesday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Wednesday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Thursday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Friday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Saturday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Sunday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
  ])

  const [current, setCurrent] = useState(null);

  const [modalType, setModalType] = useState('add');

  const dispatch = useDispatch();

  useEffect(() => {
    setSchedule(data)
  }, [data])

  const [open, setOpen] = useState(1);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const toggleModal = data => {
    setCurrent(data)
    setModalType(data ? 'edit' : 'add');
    setName(data?.name || '')
    setFrom(data?.hour_begin || '')
    setTo(data?.hour_end || '')

    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data)
    setModal1(true)
  }

  const user = JSON.parse(localStorage.authUser)
  const restoId = user.resto

  const submitModal = () => {
    if (modalType === 'add') dispatch(addShift({ id: restoId, name, hourB: from, hourE: to }))
    if (modalType === 'edit') dispatch(editShift({ id: current.id, name, hourB: from, hourE: to }))
    return setModal(false)
  }

  const deleteShift = () => {
    dispatch(removeShift(current.id))
    return setModal1(false)
  }

  const setTime = (date, fromOrTo, dayIndex, shiftIndex) => {
    const [...newSchedule] = schedule
    if (date.length > 0) {
      // Format to "HH:MM" using JavaScript's Date methods
      const hours = date[0].getHours().toString().padStart(2, '0');
      const minutes = date[0].getMinutes().toString().padStart(2, '0');
      if (fromOrTo === 'from') {
        newSchedule[dayIndex].shifts[shiftIndex].from = `${hours}:${minutes}`
        return setSchedule(newSchedule)
      }
      if (fromOrTo === 'to') {
        newSchedule[dayIndex].shifts[shiftIndex].to = `${hours}:${minutes}`
        return setSchedule(newSchedule)
      }
    }
  };

  useEffect(() => {
    if (data && q) {
      const filteredShifts = data.filter(el => {
        const name = el.name.toLowerCase();
        return name.includes(q.toLowerCase())
      })
      return setShifts(filteredShifts) 
    }
    else setShifts(data)
  }, [q, data?.length])

  const getShift = index => {
    if (index === 0) return 'Morning'
    if (index === 1) return 'Afternoon'
    if (index === 2) return 'Evening'
  }

  const resolveIsClosed = day => {
    return (
      !day.shift1_start &&
      !day.shift1_end &&
      !day.shift2_start &&
      !day.shift2_end &&
      !day.shift3_start &&
      !day.shift3_end
    )
  }

  const setShift = (id, date, type, dayIndex, shift) => {
    const [...newSchedule] = schedule
    let hours = null
    let minutes = null
    if (date?.length > 0) {
      // Format to "HH:MM" using JavaScript's Date methods
      hours = date[0].getHours().toString().padStart(2, '0');
      minutes = date[0].getMinutes().toString().padStart(2, '0');
    }
    if (shift === 1) {
      if (type === 'from') {
        newSchedule[dayIndex].shift1_start = `${hours}:${minutes}`
        dispatch(editShift({ id, shift1_start: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
      if (type === 'to') {
        newSchedule[dayIndex].shift1_end = `${hours}:${minutes}`
        dispatch(editShift({ id, shift1_end: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
    }
    if (shift === 2) {
      if (type === 'from') {
        newSchedule[dayIndex].shift2_start = `${hours}:${minutes}`
        dispatch(editShift({ id, shift2_start: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
      if (type === 'to') {
        newSchedule[dayIndex].shift2_end = `${hours}:${minutes}`
        dispatch(editShift({ id, shift2_end: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
    }
    if (shift === 3) {
      if (type === 'from') {
        newSchedule[dayIndex].shift3_start = `${hours}:${minutes}`
        dispatch(editShift({ id, shift3_start: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
      if (type === 'to') {
        newSchedule[dayIndex].shift3_end = `${hours}:${minutes}`
        dispatch(editShift({ id, shift3_end: date ? new Date(date) : date }))
        return setSchedule(newSchedule)
      }
    }
  }

  const resolveTime = val => {
    if (val) {
      const timeRegex = /^\d{2}:\d{2}$/;
      if (timeRegex.test(val)) {
        return val; // Return the value as-is
      }
      const date = new Date(val);
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`
    }

    return val

  }
 
    return (
        <>
            <UncontrolledAccordion flush stayOpen>
              {schedule?.map((el, index) => (
                <AccordionItem>
                  <AccordionHeader targetId={index + 1}>
                    <div className="d-flex align-items-center justify-content-between flex-wrap" style={{ width: '95%' }}>
                      <b className="flex-grow-1">{t(el.week_day)}</b>
                      {resolveIsClosed(el) && <span className="badge badge-soft-secondary me-2 p-2">Closed</span>}
                      <div className='d-flex'>
                        {el.shift1_start && el.shift1_end && <span className="badge badge-soft-success warning me-2 p-2">{resolveTime(el.shift1_start)} {t('To').toLocaleLowerCase()} {resolveTime(el.shift1_end)}</span>}
                        {el.shift2_start && el.shift2_end && <span className="badge badge-soft-warning warning me-2 p-2">{resolveTime(el.shift2_start)} {t('To').toLocaleLowerCase()} {resolveTime(el.shift2_end)}</span>}
                        {el.shift3_start && el.shift3_end && <span className="badge badge-soft-info warning me-2 p-2">{resolveTime(el.shift3_start)} {t('To').toLocaleLowerCase()} {resolveTime(el.shift3_end)}</span>}
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={index + 1}>
                    <FormGroup>
                      <Row>
                        <Col md={4} className='my-auto'>
                          <Label for="morning" className="d-block">{t('Morning')}</Label>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center">
                            <div className='position-relative flex-grow-1'>
                              <Flatpickr
                                value={el.shift1_start}
                                onChange={val => setShift(el.id, val, 'from', index, 1)}
                                className="me-2"
                                placeholder='From'
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'H:i', // Format to only show hour and minute
                                  time_24hr: true // Use 24-hour time format
                                }}
                                style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                              />
                              {el.shift1_start && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'from', index, 1)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                            </div>
                            <div className='position-relative flex-grow-1'>
                              <Flatpickr
                                value={el.shift1_end}
                                onChange={val => setShift(el.id, val, 'to', index, 1)}
                                placeholder='To'
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: 'H:i', // Format to only show hour and minute
                                  time_24hr: true // Use 24-hour time format
                                }}
                                style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                              />
                              {el.shift1_end && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'to', index, 1)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md={4} className='my-auto'>
                          <Label for="morning" className="d-block">{t('Afternoon')}</Label>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center">
                          <div className='position-relative flex-grow-1'>
                            <Flatpickr
                              value={el.shift2_start}
                              onChange={val => setShift(el.id, val, 'from', index, 2)}
                              className="me-2"
                              placeholder='From'
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                              }}
                              style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                            {el.shift2_start && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'from', index, 2)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                          </div>
                          <div className='position-relative flex-grow-1'>
                            <Flatpickr
                              value={el.shift2_end}
                              onChange={val => setShift(el.id, val, 'to', index, 2)}
                              placeholder='To'
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                              }}
                              style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                            {el.shift2_end && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'to', index, 2)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                          </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md={4} className='my-auto'>
                          <Label for="morning" className="d-block">{t('Evening')}</Label>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center">
                          <div className='position-relative flex-grow-1'>
                            <Flatpickr
                              value={el.shift3_start}
                              onChange={val => setShift(el.id, val, 'from', index, 3)}
                              className="me-2"
                              placeholder='From'
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                              }}
                              style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                            {el.shift3_start && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'from', index, 3)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                          </div>
                          <div className='position-relative flex-grow-1'>
                            <Flatpickr
                              value={el.shift3_end}
                              onChange={val => setShift(el.id, val, 'to', index, 3)}
                              placeholder='To'
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                              }}
                              style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                            {el.shift3_end && (
                                <button
                                  type="button"
                                  onClick={() => setShift(el.id, null, 'to', index, 3)} // Clear the value
                                  style={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", background: "transparent", border: "none", cursor: "pointer", color: "#999", fontSize: 16 }}
                                  title="Clear"
                                >
                                  ×
                                </button>
                              )}
                          </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </AccordionBody>
                </AccordionItem>
              ))}
            </UncontrolledAccordion>
        </>
    );
};

export default withRouter(withTranslation()(Shifts));