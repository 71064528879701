import { ADD_ROOM, ADD_TABLE, DELETE_ROOM, DELETE_TABLE, EDIT_ROOM, EDIT_TABLE, FETCH_ROOM, FETCH_ROOMS, FETCH_TABLES, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  rooms: [],
  room: null,
  loading: false,
  error: "",
  success: "",
};

const table = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TABLES:
      state = { ...state, loading: true };
      break;
    case SET_TABLES:
      state = { ...state, loading: false, data: action.payload };
      break;
    case FETCH_ROOMS:
        state = { ...state, loading: true };
        break;
    case FETCH_ROOM:
        state = { ...state, loading: true };
        break;
    case SET_ROOMS:
        state = { ...state, loading: false, rooms: action.payload };
        break;
    case SET_ROOM:
        state = { ...state, loading: false, room: action.payload };
        break;
    case ADD_ROOM:
        state = { ...state, loading: true };
        break;
    case EDIT_ROOM:
      state = { ...state, loading: true };
      break;
    case ADD_TABLE:
      state = { ...state, loading: true };
      break;
    case EDIT_TABLE:
      state = { ...state, loading: true };
      break;
    case DELETE_ROOM:
      state = { ...state, loading: true };
      break;
    case DELETE_TABLE:
      state = { ...state, loading: true };
      break;
    case TABLE_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case TABLE_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default table;
