export const FETCH_RESERVATIONS = "FETCH_RESERVATIONS";
export const SET_RESERVATIONS = "SET_RESERVATIONS";
// export const ADD_RESERVATION = "ADD_RESERVATION";
// export const EDIT_RESERVATION = "EDIT_RESERVATION";
// export const DELETE_RESERVATION = "DELETE_RESERVATION";
export const FETCH_DETAILS = "FETCH_DETAILS";
export const SET_DETAILS = "SET_DETAILS";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_NOTE = "ADD_NOTE";
export const RESERVATION_SUCCESS = "RESERVATION_SUCCESS"
export const RESERVATION_ERROR = "RESERVATION_ERROR"

