import React from "react";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import OverView from "./OverView";
import Dountchart from './DountChart';
import PieChart from './PieChart';
import ColumnWithDataLabels from './ColumnWithDataLabels';
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Rapports = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Mymeal" breadcrumbItem="Rapports" />
          {/* User Panel Charts */}
          <OverView />
          <Row>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>{t('Channel of reservation')}</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>{t('Walk-in vs Reservation')}</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>{t('Reservation status')}</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={9}>
                    <Card>
                        <CardBody>
                            <CardTitle>Performance</CardTitle>
                            <ColumnWithDataLabels />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card>
                        <CardBody>
                            <CardTitle>{t('Cover source')}</CardTitle>
                            <PieChart />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Rapports));
