import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row, UncontrolledDropdown } from 'reactstrap';
import { addKitchen, fetchKitchen, removeKitchen } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const Kitchen = ({ data, kitchens }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [kitchen, setKitchen] = useState([]);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const [ks, setKs] = useState([]);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setKitchen([])
    const kitchenIds = data.map(el => el.t_kitchen?.id)
    const allKitchenIds = kitchens.map(el => el?.id)
    const theIds = allKitchenIds.filter(el => kitchenIds.includes(el))
    setKitchen(theIds)
    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data)
    setModal1(true)
  }

  const submitModal = () => {
    dispatch(addKitchen({ kitchens: kitchen }))
    return setModal(false)
  }

  const deleteKitchen = () => {
    dispatch(removeKitchen(current?.kitchen))
    return setModal1(false)
  }

  const setValues = val => {
    let array = []
    if (kitchen.includes(val)) array = kitchen.filter(el => el !== val)
    else array = [...kitchen, val]
    setKitchen(array)
    console.log(array)
  }

  useEffect(() => {
    if (data && q) {
      const filteredKitchens = data.filter(el => {
        const name = el.t_kitchen?.description.toLowerCase();
        return name.includes(q.toLowerCase())
      })
      return setKs(filteredKitchens) 
    }
    else setKs(data)
  }, [q, data?.length])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.t_kitchen?.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Description</span>,
            selector: row => row.t_kitchen?.description,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <button onClick={() => toggleModal1(cell)} className="btn btn-sm me-2 shadow"><i className="ri-delete-bin-line align-bottom text-danger"></i></button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col md={6} />
                <Col md={6}>
                    <div className="d-flex align-items-center justify-content-end">
                        <form className="app-search d-none d-lg-block p-0">
                            <div className="position-relative">
                            <Input
                              value={q}
                              onChange={e => setQ(e.target.value)}
                              type="text"
                              placeholder="Search..."
                              name="q" />
                            <span className="ri-search-line"></span>
                            </div>
                        </form>
                        <Button onClick={() => toggleModal()} color="primary" className="add-btn ms-2" id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                    </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={ks}
            />

            <Modal
              isOpen={modal}
              toggle={() => { toggleModal() }}
              centered
              scrollable={true}
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Add kitchen
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body" style={{ maxHeight: 400 }}>
              <form
                  onSubmit={(e) => {
                  e.preventDefault();
                  submitModal();
                  return false;
                  }}
              >
                {kitchens.map(el => (
                  <FormGroup
                    check
                    className="py-1"
                  >
                    <Input checked={kitchen?.includes(el?.id)} type="checkbox" value={el?.id} onClick={e => setValues(parseInt(e.target.value))} />
                    <Label check>
                      {el?.name}
                    </Label>
                  </FormGroup>
                ))}
              <div className="modal-footer">
                  <button disabled={!kitchen || kitchen?.length === data?.length} type="submit" class="btn btn-primary">Add</button>
              </div>
              </form>
              </div>
            </Modal>

            <Modal
              isOpen={modal1}
              toggle={() => { toggleModal1() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Delete kitchen
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal1(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to delete <strong>{current?.t_kitchen?.description}</strong>?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
              <button onClick={() => deleteKitchen()} type="submit" class="btn btn-danger px-4">Yes</button>
              <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

              </div>
            </Modal>
        </>
    );
};

export default Kitchen;