import { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink, Modal } from "reactstrap";
import classnames from "classnames";
import Details from "../reservations/details";
import { useDispatch } from "react-redux";
import { fetchDetails } from '../../store/reservation/actions';
import { putDashboardReservation } from "../../helpers/backend_helper";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Approval = ({ data, refresh }) => {
  const [activeTab, setActiveTab] = useState('today');
  const [selected, setSelected] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const [current, setCurrent] = useState({});
  const [modal1, setModal1] = useState(false);

  const dispatch = useDispatch();

    useEffect(() => {
      if (activeTab === 'today') setSelected(data.today || [])
      if (activeTab === 'next-week') setSelected(data.next_week || [])
      if (activeTab === 'af-next-week') setSelected(data.after_next_week || [])
    }, [data])

    const toggleDetails = id => {
      console.log(id)
      dispatch(fetchDetails(id))
      setDetailsModal(!detailsModal);
    }

    const resolveTime = val => {
      if (val) {
        const timeRegex = /^\d{2}:\d{2}$/;
        if (timeRegex.test(val)) {
          return val; // Return the value as-is
        }
        const date = new Date(val);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`
      }
  
      return val
  
    }

    const resolveDate = isoDate => {
      if (!isoDate) return null; // Handle invalid input
      const date = new Date(isoDate);
    
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getUTCDate()).padStart(2, '0');
    
      return `${year}-${month}-${day}`;
    }

    const handleAction = async (action, id) => {
      if (action === 'accept') await putDashboardReservation({ id, status: 3 })
      if (action === 'reject') await putDashboardReservation({ id, status: 14  })
      setModal1(false)
      return refresh()
    }

    const toggleModal1 = el => {
      setCurrent(el)
      setModal1(true)
    }

    return (
        <Card>
          <CardBody>
            <CardTitle>Reservations</CardTitle>

            <div className="pe-3">
              <Row>
                <Col md="3">
                  <Nav
                    pills
                    className="nav flex-column nav-pills text-center"
                  >
                    <NavItem className="py-1">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === 'today',
                          'd-flex': true,
                        })}
                        onClick={() => { setActiveTab('today'); setSelected(data.today) }}
                      >
                        {t('Today')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === 'tomorrow',
                          'd-flex': true,
                        })}
                        onClick={() => { setActiveTab('tomorrow'); setSelected(data.tomorrow) }}
                      >
                        {t('Tomorrow')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === 'next-week',
                          'd-flex': true,
                        })}
                        onClick={() => { setActiveTab('next-week'); setSelected(data.next_week) }}
                      >
                        {t('Next week')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === 'af-next-week',
                          'd-flex': true,
                        })}
                        onClick={() => { setActiveTab('af-next-week'); setSelected(data.after_next_week) }}
                      >
                        {t('After next week')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="9">
                        <TabContent
                          activeTab={activeTab}
                          className="text-muted mt-4 mt-md-0"
                          style={{ height: 300, overflow: 'scroll' }}
                        >
                          {selected?.map((el, index) => (
                            <TabPane key={index} tabId={activeTab}>
                              <Row className="mb-3">
                                <Col md={6} sm={12}>
                                  <div className="d-flex align-items-center mb-1 align-items-center">
                                    {el.t_user_t_resa_clientTot_user?.firstname && <span className="badge badge-soft-secondary p-2 me-2"> {el.t_user_t_resa_clientTot_user?.firstname} {el.t_user_t_resa_clientTot_user?.lastname} </span>}
                                    <span className="badge p-2" style={{ backgroundColor: el.t_status_items?.iconBG }}>
                                      <i className={`mdi me-1 ${el.t_status_items?.iconName}`} />
                                      {el.t_status_items?.name}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-calendar-line me-1"></i>{resolveDate(el.resa_date)} {t('at')} <span className="text-nowrap ms-2">{resolveTime(el.resa_hour)}</span></span>
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-restaurant-2-line me-1"></i>{el.covers}</span>
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-time-line me-1"></i>{t('Sent at')} {resolveDate(el.created)}</span>
                                  </div>
                                </Col>
                                <Col md={6} sm={12}>
                                  <div className="d-flex justify-content-end align-items-center mt-2">
                                    <button onClick={() => { toggleDetails(el.id) }} type="button" className="btn btn-sm btn-light me-1"> <i className="ri-user-line"></i></button>
                                    {(el.resa_stat === 1) && <button onClick={() => { handleAction('accept', el.id) }} type="button" className="btn btn-success btn-sm me-1"><i className="ri-check-fill"></i>{t('Accept')}</button>}
                                    {(el.resa_stat === 1) && <button onClick={() => { toggleModal1(el) }} type="button" className="btn btn-danger btn-sm me-1"><i className="ri-close-line"></i>{t('Reject')}</button>}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                          {selected.length === 0 && <h5 className="d-flex justify-content-center align-items-center text-secondary" style={{ height: 150 }}>{t('No results')}</h5>}
                        </TabContent>
                </Col>
              </Row>
            </div>
          </CardBody>

          <Modal
            isOpen={detailsModal}
            toggle={() => { toggleDetails() }}
            centered
            scrollable={true}
            size="lg"
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                Reservation details
                </h5>
                <button
                type="button"
                onClick={() => { setDetailsModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ maxHeight: 500 }}>
                <Details />
            </div>
          </Modal>
          <Modal
              isOpen={modal1}
              toggle={() => { toggleModal1() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Reject reservation
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal1(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to reject <strong>{current?.ref}</strong>?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
              <button onClick={() => handleAction('reject', current.id)} type="submit" class="btn btn-danger px-4">Yes</button>
              <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

              </div>
            </Modal>
        </Card>
    )
}
export default withRouter(withTranslation()(Approval));

