import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap"
import { addNote } from "../../store/actions";
import { t } from "i18next";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Notes = ({ closeModal }) => {
    const data = useSelector(state => state.reservation.details)
    const [note, setNote] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setNote(data.note);
    }, [data])

    const sendNote = () => {
        dispatch(addNote({ resa: data.id, note }))
        closeModal();
    }
    return (
        <div nobody>
            <Input value={note} placeholder={`${t('No notes yet')}..`} onChange={e => setNote(e.target.value)} type="textarea" rows={10} />
            <div className="d-flex justify-content-end my-2">
                <button onClick={() => sendNote()} disabled={note === data.note} className="btn btn-primary">{t('Save changes')}</button>
            </div>
        </div>
    )
}
export default withRouter(withTranslation()(Notes));

