

//LOGIN
export const POST_LOGIN = "/auth/login?lang=en";
export const POST_PASSWORD_FORGET = "/auth/requestresetpassword";

//PROFILE
export const POST_EDIT_PROFILE = "/post-fake-profile";
export const GET_FETCH_PROFILE = "/user/info/me";
export const POST_PROFILE = "/user/edit";
export const PUT_PROFILE_EMAIL = "/user/editEmail";
export const PUT_PROFILE_PASSWORD = "/user/editPassword";

// Resto
export const GET_FETCH_RESTO = "/resto";
export const PUT_RESTO_INFO = "/resto/editInfo";
export const PUT_RESTO_CONTACT = "/resto/editData";
export const PUT_RESTO_ADDRESS = "/resto/editAddress";
export const GET_FETCH_KITCHEN = "/kitchen/all";

// Users
export const PUT_RESTO_USER = "/user/editUser";
export const POST_RESTO_USER = "/user";
export const DELETE_RESTO_USER = "/user";

// Shifts
export const PUT_SHIFT = "/shift";
export const POST_SHIFT = "/shift";
export const DELETE_SHIFT = "/shift";
export const GET_FETCH_ROLES = "/role";

// Kitchen
export const POST_KITCHEN = "/kitchen/addKitchen";
export const DELETE_KITCHEN = "/kitchen";

// Reservation
export const GET_FETCH_RESERVATIONS = "/resa/all";
export const GET_FETCH_DETAILS = "/resa";
export const POST_COMMENT = "/resa/comment";
export const POST_NOTE = "/resa/note";

// Tables & Rooms
export const GET_FETCH_TABLES = "/tables/all";
export const POST_ROOM = "/room";
export const PUT_ROOM = "/room";
export const DELETE_ROOM = "/room";
export const GET_FETCH_ROOMS = "/room/resto";
export const GET_FETCH_ROOM = "/room";
export const DELETE_TABLE = "/tables";
export const POST_TABLE = "/tables";
export const PUT_TABLE = "/tables";

// Menus
export const GET_FETCH_MENUS = "/menu/all";
export const GET_FETCH_MENU = "/menu";
export const POST_MENU = "/menu";
export const PUT_MENU = "/menu";
export const PUT_MENU_ORDER = "/menu/editOrder";
export const DELETE_MENU = "/menu";
export const POST_CATEGORY = "/menu/category";
export const PUT_CATEGORY = "/menu/category";
export const PUT_CATEGORY_ORDER = "/menu/reorder/category";
export const DELETE_CATEGORY = "/menu/category";
export const POST_ITEM = "/menu/item";
export const PUT_ITEM = "/menu/item";
export const PUT_ITEM_ORDER = "/menu/reorder/item";
export const DELETE_ITEM = "/menu/item";

// Invoice
export const GET_FETCH_INVOICES = "/invoice/all";
export const GET_FETCH_INVOICE = "/invoice";
export const POST_INVOICE = "/invoice";
export const PUT_INVOICE = "/invoice";
export const DELETE_INVOICE = "/invoice";

// Promotion
export const GET_FETCH_PROMOTIONS = "/promos/all";
export const GET_FETCH_PROMOTION = "/promos";
export const POST_PROMOTION = "/promos";
export const PUT_PROMOTION = "/promos";
export const DELETE_PROMOTION = "/promos";

// Image
export const GET_FETCH_IMAGES = "/images/all";
export const POST_UPLOAD_IMAGE = "/images";
export const POST_IMAGE = "/upload";
export const PUT_IMAGE = "/images/editOrder";
export const DELETE_IMAGE = "/images";

// Contract
export const GET_FETCH_CONTRACTS = "/contract/all";
export const GET_FETCH_CONTRACT = "/contract";
export const POST_CONTRACT = "/contract";
export const PUT_CONTRACT = "/contract";
export const DELETE_CONTRACT = "/contract";

// Dashboard
export const GET_FETCH_DASHBOARD = '/dashboard'
export const PUT_DASHBOARD_RESERVATION = '/resa/status'

// Calendar
export const GET_EVENTS = '/calendar/all'
export const ADD_NEW_EVENT = '/resa'
export const UPDATE_EVENT = '/resa/status'
export const DELETE_EVENT = '/resa/status'

// Packs
export const GET_PACKS = '/packs/all'
export const PUT_PACK = '/packs/set-pack'

// Reservation hours
export const PUT_RES_HOURS = '/resto/editHours'
