import { ADD_CATEGORY, ADD_ITEM, ADD_MENU, DELETE_CATEGORY, DELETE_ITEM, DELETE_MENU, EDIT_CATEGORY, EDIT_ITEM, EDIT_MENU, FETCH_MENU, FETCH_MENUS, MENU_ERROR, MENU_SUCCESS, SET_MENU, SET_MENUS, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  menu: null,
  loading: false,
  error: "",
  success: "",
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENUS:
      state = { ...state, loading: true };
      break;
    case SET_MENUS:
      state = { ...state, loading: false, data: action.payload };
      break;
    case FETCH_MENU:
        state = { ...state, loading: true };
        break;
    case SET_MENU:
        state = { ...state, loading: false, menu: action.payload };
        break;
    case ADD_MENU:
        state = { ...state, loading: true };
        break;
    case EDIT_MENU:
      state = { ...state, loading: true };
      break;
    case DELETE_MENU:
      state = { ...state, loading: true };
      break;
    case ADD_CATEGORY:
        state = { ...state, loading: true };
        break;
    case EDIT_CATEGORY:
      state = { ...state, loading: true };
      break;
    case DELETE_CATEGORY:
      state = { ...state, loading: true };
      break;
    case ADD_ITEM:
        state = { ...state, loading: true };
        break;
    case EDIT_ITEM:
      state = { ...state, loading: true };
      break;
    case DELETE_ITEM:
      state = { ...state, loading: true };
      break;
    case MENU_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case MENU_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default menu;
