import { EDIT_RESTO_ADDRESS, EDIT_RESTO_INFO, FETCH_RESTO, RESTO_ERROR, RESTO_SUCCESS, SET_RESTO, FETCH_KITCHEN, SET_KITCHEN, EDIT_RESTO_CONTACT, FETCH_ROLES, SET_ROLES, ADD_USER, EDIT_USER, DELETE_USER, EDIT_CONFIG, EDIT_RES_HOURS } from "./actionTypes";

const initialState = {
  resto: {},
  roles: [],
  kitchen: [],
  loading: false,
  error: "",
  success: "",
};

const restaurant = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      state = { ...state, loading: true };
      break;
    case ADD_USER:
      state = { ...state, loading: true };
      break;
    case EDIT_USER:
      state = { ...state, loading: true };
      break;
    case DELETE_USER:
      state = { ...state, loading: true };
      break;
    case SET_ROLES:
      state = { ...state, loading: false, roles: action.payload };
      break;
    case FETCH_RESTO:
      state = { ...state, loading: true };
      break;
    case FETCH_KITCHEN:
      state = { ...state, loading: true };
      break;
    case SET_KITCHEN:
      state = { ...state, loading: false, kitchen: action.payload };
      break;
    case SET_RESTO:
    state = { ...state, loading: false, resto: action.payload };
      break;
    case EDIT_RESTO_INFO:
      state = { ...state, loading: true };
      break;
    case EDIT_RESTO_CONTACT:
      state = { ...state, loading: true };
      break;
    case EDIT_RESTO_ADDRESS:
      state = { ...state, loading: true };
      break;
    case EDIT_CONFIG:
      state = { ...state, loading: true };
      break;
    case EDIT_RES_HOURS:
      state = { ...state, loading: true };
      break;
    case RESTO_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case RESTO_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default restaurant;
