
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { editEmail, editPassword, editProfile, fetchProfile } from "../../store/actions";

import { createSelector } from 'reselect';
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const UserProfile = () => {
  document.title = "Profile | Mymeal";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const userprofilepage = createSelector(
    (state ) => state.profile,
    (state) => ({
        error: state.error,
        success: state.success,
    })
  );
// Inside your component
const { error, success } = useSelector(userprofilepage);
const user = useSelector(state => state.profile.user)
  useEffect(() => {
    dispatch(fetchProfile())
  }, [dispatch]);

  useEffect(() => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setemail(user.email);
    setPhone(user.phone);
    setGender(user.data?.genre);

    //Email
    setCurrentEmail(user.email);
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: firstname || "",
      lastname: lastname || "",
      email: email || "",
      phone: phone || "",
      gender: gender || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your firstname"),
      lastname: Yup.string().required("Please Enter Your lastname"),
      email: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  const validation1 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: currentPassword || "",
      newPassword: newPassword || "",
      confirmPassword: confirmPassword || "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please Enter current password"),
      newPassword: Yup.string().required("Please Enter new password"),
      confirmPassword: Yup.string().required("Please Enter confirm password"),
    }),
    onSubmit: (values) => {
      dispatch(editPassword(values));
    },
  });

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentEmail: currentEmail || "",
      newEmail: newEmail || "",
      confirmEmail: confirmEmail || "",
    },
    validationSchema: Yup.object({
      currentEmail: Yup.string().required("Please Enter current email"),
      newEmail: Yup.string().required("Please Enter new email"),
      confirmEmail: Yup.string().required("Please Enter confirm email"),
    }),
    onSubmit: (values) => {
      dispatch(editEmail(values));
    },
  });

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Mymeal" breadcrumbItem="Profile" />

            <h4 className="card-title mb-4">{t('Overview')}</h4>

            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xl={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">{t('First name')}</Label>
                        <Input
                          name="firstname"
                          // value={name}
                          className="form-control"
                          placeholder={t('First name')}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.firstname}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">{t('Last name')}</Label>
                        <Input
                          name="lastname"
                          // value={name}
                          className="form-control"
                          placeholder={t('Last name')}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.lastname}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">{t('Email')}</Label>
                        <Input
                          name="email"
                          // value={name}
                          className="form-control"
                          placeholder={t('Email')}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                            validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                        validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">{t('Phone')}</Label>
                        <Input
                          name="phone"
                          // value={name}
                          className="form-control"
                          placeholder={t('Phone')}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone &&
                            validation.errors.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone &&
                        validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.phone}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">{t('Gender')}</Label>
                        <select className="form-control">
                          <option value={null} selected={!gender}>{t('Select')} {gender}</option>
                          <option value="male" selected={gender === 'male'}>{t('Male')}</option>
                          <option value="female" selected={gender === 'female'}>{t('Female')}</option>
                        </select>
                        {/* <Input
                          name="gender"
                          // value={name}
                          className="form-control"
                          placeholder="Enter User Name"
                          type="gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gende || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.username}</div>
                          </FormFeedback>
                        ) : null} */}
                      </div>
                    </Col>
                    <Col xl={4} sm={6}>
                      <div className="text-center mt-4 d-flex justify-content-end">
                        <Button type="submit" color="primary">
                          {t('Save changes')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>

            <Row>
              <Col xl={6} sm={12}>
                <h4 className="card-title mb-4">{t('Password')}</h4>
                <Card>
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation1.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="form-group">
                        <Label className="form-label">{t('Current password')}</Label>
                        <Input
                          name="currentPassword"
                          // value={name}
                          className="form-control"
                          placeholder="********"
                          type="password"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.currentPassword || ""}
                          invalid={
                            validation1.touched.currentPassword &&
                            validation1.errors.currentPassword
                              ? true
                              : false
                          }
                        />
                        {validation1.touched.currentPassword &&
                        validation1.errors.currentPassword ? (
                          <FormFeedback type="invalid">
                            <div>{validation1.errors.currentPassword}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <Label className="form-label">{t('New password')}</Label>
                        <Input
                          name="newPassword"
                          // value={name}
                          className="form-control"
                          placeholder="********"
                          type="password"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.newPassword || ""}
                          invalid={
                            validation1.touched.newPassword &&
                            validation1.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        {validation1.touched.newPassword &&
                        validation1.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            <div>{validation1.errors.newPassword}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <Label className="form-label">{t('Confirm password')}</Label>
                        <Input
                          name="confirmPassword"
                          // value={name}
                          className="form-control"
                          placeholder="********"
                          type="password"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.confirmPassword || ""}
                          invalid={
                            validation1.touched.confirmPassword &&
                            validation1.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation1.touched.confirmPassword &&
                        validation1.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            <div>{validation1.errors.confirmPassword}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-center mt-4 d-flex justify-content-end">
                        <Button type="submit" color="primary">
                          {t('Save changes')}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6} sm={12}>
                <h4 className="card-title mb-4">{t('Email')}</h4>
                <Card>
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation2.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="form-group">
                        <Label className="form-label">{t('Current email')}</Label>
                        <Input
                          name="currentEmail"
                          // value={name}
                          className="form-control"
                          placeholder={t('Email')}
                          type="email"
                          onChange={validation2.handleChange}
                          onBlur={validation2.handleBlur}
                          value={validation2.values.currentEmail || ""}
                          invalid={
                            validation2.touched.currentEmail &&
                            validation2.errors.currentEmail
                              ? true
                              : false
                          }
                        />
                        {validation2.touched.currentEmail &&
                        validation2.errors.currentEmail ? (
                          <FormFeedback type="invalid">
                            <div>{validation2.errors.currentEmail}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <Label className="form-label">{t('New email')}</Label>
                        <Input
                          name="newEmail"
                          // value={name}
                          className="form-control"
                          placeholder={t('New email')}
                          type="email"
                          onChange={validation2.handleChange}
                          onBlur={validation2.handleBlur}
                          value={validation2.values.newEmail || ""}
                          invalid={
                            validation2.touched.newEmail &&
                            validation2.errors.newEmail
                              ? true
                              : false
                          }
                        />
                        {validation2.touched.newEmail &&
                        validation2.errors.newEmail ? (
                          <FormFeedback type="invalid">
                            <div>{validation2.errors.newEmail}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <Label className="form-label">{t('Confirm email')}</Label>
                        <Input
                          name="confirmEmail"
                          // value={name}
                          className="form-control"
                          placeholder={t('Confirm email')}
                          type="email"
                          onChange={validation2.handleChange}
                          onBlur={validation2.handleBlur}
                          value={validation2.values.confirmEmail || ""}
                          invalid={
                            validation2.touched.confirmEmail &&
                            validation2.errors.confirmEmail
                              ? true
                              : false
                          }
                        />
                        {validation2.touched.confirmEmail &&
                        validation2.errors.confirmEmail ? (
                          <FormFeedback type="invalid">
                            <div>{validation2.errors.confirmEmail}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-center mt-4 d-flex justify-content-end">
                        <Button type="submit" color="primary">
                          {t('Save changes')}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(UserProfile));

