import { t } from "i18next";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/Common/withRouter";

const UserPanel = ({ data }) => {
  return (
    <React.Fragment>
      <div className="d-flex align-itesm-center flex-wrap">
          <Card className="me-3 flex-grow-1">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-restaurant-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{t('Total covers')}</p>
                  <h5 className="mb-3">{data?.total_couvers}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="me-3 flex-grow-1">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-checkbox-multiple-fill"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-nowrap">{t('Completed')}/{t('Upcoming')}</p>
                  <h5 className="mb-3">{data?.completed} / {data?.upcoming}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="me-3 flex-grow-1">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-walk-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{t('Walk-ins')}</p>
                  <h5 className="mb-3">{data?.walk_in}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="me-3 flex-grow-1">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{t('First time guests')}</p>
                  <h5 className="mb-3">{data?.first_time_guest}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="flex-grow-1">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-vip-diamond-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{t('VIP reservations')}</p>
                  <h5 className="mb-3">{data?.vip_resa}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(UserPanel));
