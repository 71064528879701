import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { useDrag, useDrop } from "react-dnd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editItem, removeItem } from "../../store/actions";
const ItemType = "BOX";

const ElItem = ({ el, index, catIndex, moveEl, setElementName, deleteElement }) => {
  const [itemName, setItemName] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const [itemDescription, setItemDescription] = useState(null);
  const [itemModal, setItemModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveEl(draggedItem.index, index, catIndex);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveEl(draggedItem.index, index, catIndex);
        draggedItem.index = index;
      }
    },
  });

  const toggleItemModal = () => {
    setItemModal(!itemModal);
    setItemName(el.name);
    setItemPrice(el.price);
    setItemDescription(el.description);
  }

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  }

  const updateItem = () => {
    dispatch(editItem({ id: el.id, name: itemName, price: itemPrice, description: itemDescription }))
    setItemModal(false);
  }

  const deleteItem = () => {
    dispatch(removeItem(el.id))
  }
  
  return (
      <div className="row">
        <Col md={2} className="d-flex justify-content-end align-items-center">
            <span ref={(node) => drag(drop(node))} style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }}>
                <b>☰</b>
            </span>
        </Col>
          <Col md={7} className="d-flex align-items-center">
            <div>
              <span>{el.name}</span>
              <p className="text-secondary mb-0">{el.description}</p>
            </div>
          </Col>
          <Col md={3} className="">
                  <div className="d-flex justify-content-end align-items-center">
                  <span className="me-4">{el.price}</span>
                    <button onClick={() => toggleItemModal()} type="button" className="btn btn-sm">
                      <i class="ri-edit-line"></i>
                    </button>
                    <button onClick={() => toggleDeleteModal()} type="button" className="btn btn-sm">
                      <i class="ri-delete-bin-line text-danger"></i>
                    </button>
                  </div>
          </Col>
          <Modal
            isOpen={itemModal}
            toggle={() => { toggleItemModal() }}
            centered
            >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    Add item
                  </h5>
                  <button
                    type="button"
                    onClick={() => { setItemModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body" style={{ maxHeight: 400 }}>
                  <FormGroup className="flex-grow-1">
                      <Label for="table">Name</Label>
                      <Input placeholder="Item name" value={itemName} onChange={e => setItemName(e.target.value)} type="text" name="item-name" />
                  </FormGroup>
                  <FormGroup className="flex-grow-1">
                      <Label for="table">Price</Label>
                      <Input placeholder="Item price" value={itemPrice} onChange={e => setItemPrice(e.target.value)} type="number" name="price" />
                  </FormGroup>
                  <FormGroup className="flex-grow-1">
                      <Label for="table">Description</Label>
                      <Input placeholder="Sauces, compliments etc..." value={itemDescription} onChange={e => setItemDescription(e.target.value)} type="textarea" name="description" />
                  </FormGroup>
              </div>
              <div className="modal-footer d-flex justify-content-end">
                  <button onClick={() => setItemModal(false)} type="submit" class="btn btn-secondary px-4">Close</button>
                  <button onClick={() => updateItem()} type="submit" class="btn btn-primary px-4">Update</button>
              </div>
          </Modal>

          <Modal
            isOpen={deleteModal}
            toggle={() => { toggleDeleteModal() }}
            centered
          >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                  Delete category
                </h5>
                <button
                  type="button"
                  onClick={() => { setDeleteModal(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
              <h5>Do you want to delete <strong>{el?.name}</strong>?</h5>
            </div>
            <div className="modal-footer d-flex justify-content-center">
                <button onClick={() => deleteItem()} type="submit" class="btn btn-danger px-4">Yes</button>
                <button onClick={() => setDeleteModal(false)} type="submit" class="btn btn-secondary px-4">No</button>
            </div>
          </Modal>
      </div>
  )
}

export default ElItem;