import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchKitchen, fetchResto } from "../../store/actions";
import RestoCard from "./card";
import Location from "./map";
import SimpleBar from "simplebar-react";
import Users from "./users";
import Shifts from "./shifts";
import Kitchen from "./kitchen";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import i18n, { t } from "i18next";
import ReservationHours from "./reservationHours";

const Restaurant = () => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.restaurant.resto);
  const kitchens = useSelector(state => state.restaurant.kitchen);
  const user = JSON.parse(localStorage.authUser);
  const [lang, setLang] = useState('en')
  
  useEffect(() => {
    dispatch(fetchResto())
    dispatch(fetchKitchen())
  }, [])

  useEffect(() => {
    setLang(i18n.language)
  }, [i18n.language])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={t('My restaurant')} breadcrumbItem={t('Information')} />
          <Row>
            <Col >
              <SimpleBar style={{ maxHeight: 500 }}>
                <RestoCard
                  type="info"
                  title={t('My restaurant')}
                  info={{
                    id: info.id,
                    Ref: info.ref,
                    [t('Name')]: info.name,
                    [t('Description')]: lang === 'fr' ? info.description : info.data?.en_description,
                    en_description: info.data?.en_description,
                    fr_description: info?.description,
                  }}
                />

                <RestoCard
                  type="owner"
                  title={t('Owner')}
                  notEditable={true}
                  info={{
                    id: info.id,
                    [t('First name')]: user.firstname,
                    [t('Last name')]: user.lastname,
                    [t('Phone')]: user.phone,
                    [t('Email')]: user.email,
                  }}
                />

                <RestoCard
                  type="contact"
                  title={t('Contact')}
                  info={{
                    id: info.id,
                    [t('First name')]: info.data?.owner_firstname,
                    [t('Last name')]: info.data?.owner_lastname,
                    [t('Phone')]: info.data?.owner_phone,
                    [t('Email')]: info.data?.owner_email,
                  }}
                />

                <RestoCard
                  type="address"
                  title="Address"
                  info={{
                    id: info.t_address?.id,
                    line1: info.t_address?.line1,
                    line2: info.t_address?.line2,
                    [t('Zip code')]: info.t_address?.zipcode,
                    [t('City')]: info.t_address?.city,
                  }}
                />
              </SimpleBar>
              </Col>
            <Col>
              <Location />
          </Col>
          </Row>
          { /* Tables */ }
          <Card>
            <CardBody>
            <CardTitle className="mt-0">{t('Personal')}</CardTitle>
            <Users data={info.t_user} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
            <CardTitle className="mt-0">{t('Reservation hours')}</CardTitle>
            <ReservationHours data={{ id: info.id, from: info?.service_hour_begin, to: info.service_hour_begin }} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
            <CardTitle className="mt-0">{t('Shifts')}</CardTitle>
            <Shifts data={info.t_resto_shift} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
            <CardTitle className="mt-0">{t('Kitchen')}</CardTitle>
            <Kitchen data={info.r_resto_kitchen} kitchens={kitchens} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Restaurant));

