import { EDIT_RESTO_ADDRESS, EDIT_RESTO_INFO, FETCH_RESTO, RESTO_ERROR, RESTO_SUCCESS, SET_RESTO, FETCH_KITCHEN, SET_KITCHEN, EDIT_RESTO_CONTACT, ADD_KITCHEN, DELETE_KITCHEN, ADD_USER, DELETE_USER, EDIT_USER, FETCH_ROLES, SET_ROLES, DELETE_SHIFT, EDIT_SHIFT, ADD_SHIFT, EDIT_CONFIG, EDIT_RES_HOURS } from "./actionTypes"

export const fetchResto = () => {
  return {
    type: FETCH_RESTO,
  }
}

export const setResto = user => {
  return {
    type: SET_RESTO,
    payload: user,
  }
}

export const editRestoInfo = data => {
  return {
    type: EDIT_RESTO_INFO,
    payload: { data },
  }
}

export const editRestoContact = data => {
  return {
    type: EDIT_RESTO_CONTACT,
    payload: { data },
  }
}

export const editRestoAddress = data => {
  return {
    type: EDIT_RESTO_ADDRESS,
    payload: { data },
  }
}

export const editConfig = data => {
  return {
    type: EDIT_CONFIG,
    payload: { data },
  }
}

export const restoSuccess = msg => {
  return {
    type: RESTO_SUCCESS,
    payload: msg,
  }
}

export const restoError = error => {
  return {
    type: RESTO_ERROR,
    payload: error,
  }
}

export const fetchKitchen = () => {
  return {
    type: FETCH_KITCHEN,
  }
}

export const addKitchen = data => {
  return {
    type: ADD_KITCHEN,
    payload: data,
  }
}

export const removeKitchen = id => {
  return {
    type: DELETE_KITCHEN,
    payload: id,
  }
}

export const setKitchen = data => {
  return {
    type: SET_KITCHEN,
    payload: data,
  }
}

export const addUser = data => {
  return {
    type: ADD_USER,
    payload: data,
  }
}

export const editUser = data => {
  return {
    type: EDIT_USER,
    payload: data,
  }
}

export const removeUser = id => {
  return {
    type: DELETE_USER,
    payload: id,
  }
}

export const fetchRoles = data => {
  return {
    type: FETCH_ROLES,
  }
}

export const setRoles = data => {
  return {
    type: SET_ROLES,
    payload: data,
  }
}

export const addShift = data => {
  return {
    type: ADD_SHIFT,
    payload: data,
  }
}

export const editShift = data => {
  return {
    type: EDIT_SHIFT,
    payload: data,
  }
}

export const removeShift = id => {
  return {
    type: DELETE_SHIFT,
    payload: id,
  }
}

export const editResHours = data => {
  return {
    type: EDIT_RES_HOURS,
    payload: data,
  }
}
