import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { fetchDetails, fetchReservations } from '../../store/reservation/actions';
import "flatpickr/dist/themes/material_green.css";
import Details from './details';
import _ from 'lodash';
import Notes from './notes';
import Comments from './comments';
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const Reservations = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const reservations = useSelector(state => state.reservation.reservations)
  const loading = useSelector(state => state.reservation.loading)
  const [date, setDate] = useState(null)
  const [createDate, setCreateDate] = useState(null)
  const [sOpen, setSOpen] = useState(false);
  const [sOpen1, setSOpen1] = useState(false);
  const [sOpen2, setSOpen2] = useState(false);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState({ name: t('All statuses'), id: null })
  const [source, setSource] = useState({ name: t('All sources'), value: null })
  const [action, setAction] = useState({})
  const statuses = [
    { name: t('All statuses'), id: null },
    { color: "#4a4c50", iconName: "mdi-square-outline", id: 1, name: t("Unconfirmed"), status: 1 },
    { color: "#8451ca", iconName: "mdi-clock-outline", id: 12, name: t("Waiting list"), status: 2 },
    { color: "rgb(44, 148, 211)", iconName: "mdi-circle", id: 3, name: t("Confirmed"), status: 1 },
    { color: "#b00020", iconName: "mdi-close-thick", id: 15, name: t("Canceled"), status: 6 },
    { color: "#999999", iconName: "mdi-check-bold", id: 13, name: t("Completed"), status: 5 },
    { color: "#2f4858", iconName: "mdi-account-multiple-remove-outline", id: 16, name: t("No show"), status: 6 },
    { color: "#128849", iconName: "mdi-silverware-variant", id: 7, name: t("Seating"), status: 4 },
    { color: "#10b3ac", iconName: "mdi-pasta", id: 9, name: t("Main course"), status: 4 },
    { color: "#b00020", iconName: "mdi-minus-thick", id: 14, name: t("Rejected"), status: 6 },
  ]
  const sources = [
    { name: t('All sources'), value: null },
    { name: t("User"), value: 'user' },
    { name: t("Online"), value: 'on line' },
    { name: t("Walk-in"), value: 'walking' },
  ]

  const actions = [
    { name: t('Details'), icon: 'ri-user-line ri-lg', action: 'details' },
    { name: t("Notes"), icon: 'ri-sticky-note-line ri-lg', action: 'notes' },
    { name: t("Comments"), icon: 'ri-chat-1-line ri-lg', action: 'comments' },
  ]

  const toggleModal = data => {
    if (data) {
      dispatch(fetchDetails(data.id))
      if (data) setCurrent(data)
    }
    return setModal(!modal);
  }

  useEffect(() => {
    dispatch(fetchReservations({ search: q, date, created: createDate, status_id: status.id, source: source.value /* page, perPage */ }))
  }, [date, createDate, status, source /* page, perPage */])

  const debouncedSearch = useCallback(
      _.debounce((value) => {
        dispatch(fetchReservations({ search: value, date, created: createDate, status_id: status.id, source: source.value /* page, perPage */ }))
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(q)
  }, [q])

  const onAction = (cell, action) => {
    setAction(action)
    return toggleModal(cell)
  }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Date</span>,
            selector: cell => {
              return (
                <>
                  <span className="font-size-12">{cell.resa_date}</span>
                  <p className="font-size-12 m-0 mt-1">{cell.resa_hour}</p>
                </>
              )
            },
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Ref</span>,
            selector: row => row.ref,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Source</span>,
          selector: row => row.reservationType,
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Client</span>,
          selector: cell => {
            return (
              <div className='d-flex align-items-center'>
                {cell.t_user?.id === 8 && <i className="ri text-primary ri-user-2-line me-1"></i>}
                <span className="font-size-12">{cell.t_user?.firstname + ' ' + cell.t_user?.lastname}</span>
              </div>
            )
          },
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>{t('Covers')}</span>,
          selector: row => row.nb_couvert,
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>{t('Created at')}</span>,
          selector: row => row.created,
          sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: true,
            selector: (cell) => <span className="badge p-2 px-2" style={{ backgroundColor: cell.t_status_items.color }}><i className={`mdi me-1 ${cell.t_status_items.iconName}`}></i> {cell.t_status_items?.name} </span>,
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: (cell) => {
              
              return (
                <>
                  {actions.map(el => (
                    <button
                      key={el.id}
                      onClick={() => onAction(cell, el)}
                      className="btn btn-sm btn-outline-secondary me-2 text-nowrap position-relative">
                        {el.action === 'notes' && cell.note?.length > 0 &&  <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>}
                        {el.action === 'comments' && cell.t_resa_comment?.length > 0 &&  <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>}
                        <i className={`${el.icon}`}></i>
                    </button>
                    ))}
                </>                    
                );
            },
        },
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title={t("My restaurant")} breadcrumbItem="Reservations" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row className='mb-4'>
                <Col md={6}>
                    <Flatpickr
                        value={date}
                        onChange={setDate}
                        className="me-2"
                        placeholder={`${t('Search by date')}...`}
                        style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5 }}
                    />
                    <Flatpickr
                        value={createDate}
                        onChange={setCreateDate}
                        className="me-2"
                        placeholder={`${t('Search by creation date')}...`}
                        style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5 }}
                    />
                </Col>
                <Col md={6}>
                    <div className="d-flex align-items-center justify-content-end">
                        <Dropdown
                            isOpen={sOpen}
                            toggle={() => setSOpen(!sOpen)}
                            className="d-inline-block d-sm-inline-block"
                            size='sm'
                        >
                            <DropdownToggle className="btn btn-primary waves-effect" tag="button">
                            <span className="text-nowrap">
                                <i className={`mdi me-1 ${status.iconName}`}></i>
                                {status.name}
                                <i className="mdi ms-1 mdi-chevron-down"></i>
                            </span>
                            </DropdownToggle>
                            <DropdownMenu className="language-switch dropdown-menu-end">
                              {statuses.map(el => (
                                  <DropdownItem
                                  key={el.id}
                                  onClick={() => setStatus(el)}
                                  className={`notify-item ${ el.id === status.id ? "active" : "none" }`}
                              >
                                  <i className={`mdi me-1 ${el.iconName} ${el.id === status.id && 'text-primary'}`}></i>
                                  <span className={`align-middle ${el.id === status.id && 'text-primary'}`}>{el.name}</span>
                              </DropdownItem>
                              ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown
                            isOpen={sOpen1}
                            toggle={() => setSOpen1(!sOpen1)}
                            className="d-inline-block d-sm-inline-block ms-2"
                            size='sm'
                        >
                            <DropdownToggle className="btn btn-primary waves-effect" tag="button">
                            <span className="text-nowrap">
                                {source.name}
                                <i className="mdi ms-1 mdi-chevron-down"></i>
                            </span>
                            </DropdownToggle>
                            <DropdownMenu className="language-switch dropdown-menu-end">
                              {sources.map(el => (
                                  <DropdownItem
                                  key={el.value}
                                  onClick={() => setSource(el)}
                                  className={`notify-item ${ el.value === source.value ? "active" : "none" }`}
                              >
                                  <span className={`align-middle ${el.value === source.value && 'text-primary'}`}>{el.name}</span>
                              </DropdownItem>
                              ))}
                            </DropdownMenu>
                        </Dropdown>
                        <form className="app-search d-none d-lg-block p-0 ms-2" style={{ width: '100%' }}>
                            <div className="position-relative">
                            <Input
                              value={q}
                              onChange={e => setQ(e.target.value)}
                              type="text"
                              debounce={500}
                              placeholder={`${t('Search by reference, client name')}...`}
                              name="q" />
                            <span className="ri-search-line"></span>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={reservations}
                pagination
                // paginationServer
                // paginationDefaultPage={page}
                // paginationPerPage={perPage}
                // onChangePage={setPage}
                // onChangeRowsPerPage={setPerPage}
                progressPending={loading}
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size={action.action === 'details' ? 'lg' : 'md'}
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {action.name}
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 500 }}>
                    {action.action === 'details' && <Details />}
                    {action.action === 'notes' && <Notes closeModal={() => setModal(false)} />}
                    {action.action === 'comments' && <Comments />}
                </div>
            </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default withRouter(withTranslation()(Reservations));
