import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editPack, fetchPacks } from "../../store/actions";
import { t } from "i18next";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";

const PricingData = [
  
  {
    title: "Basic",
    caption: "Neque quis est",
    icon: "ri-rocket-2-fill",
    price: "120",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    title: "Pro",
    caption: "Quis autem iure",
    icon: "ri-trophy-fill",
    price: "249",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    title: "Pro+",
    caption: "Sed neque unde",
    icon: "ri-vip-crown-fill",
    price: "499",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    title: "Unlimited",
    caption: "Itque eam rerum",
    icon: "ri-vip-diamond-fill",
    price: "650",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
];

const Packs = () => {
  const [currentPlan, setCurrentPlan] = useState('monthly')
  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(fetchPacks());
  }, [])
  
  const packs = useSelector(state => state.pack.data);

  const upgradePlan = packId => {
    dispatch(editPack({ id: packId, planId: resolvePlanId() }))
  }

  const resolvePlanId = () => {
    return currentPlan === 'yearly' ? 2 : 1
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t("My restaurant")} breadcrumbItem={t("Packs")} />

          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="text-center mb-5">
                <h4>{t('Choose your Pricing plan')}</h4>
              </div>
            </Col>
          </Row>

            <div className="d-flex align-items-center justify-content-center mb-2">
              <Button className="px-4 me-2" style={{ minWidth: 180 }} onClick={() => setCurrentPlan('monthly')} type="button" color={currentPlan === 'monthly' ? 'primary' : 'light'}>
                {t('Monthly')}
              </Button>
              <Button className="px-4" style={{ minWidth: 180 }} onClick={() => setCurrentPlan('yearly')} type="button" color={currentPlan === 'yearly' ? 'primary' : 'light'}>
                {t('Yearly')}
              </Button>
            </div>
          <Row className="d-flex justify-content-center">
            {packs?.map((item, key) => (
              <Col xl={4} md={6} key={key}>
                <Card>
                  <CardBody className="p-4">
                    <div className="d-flex mb-1">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={(item.id === 1 ? 'ri-rocket-2-fill' : 'ri-trophy-fill') + " font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16">{item.name}</h5>
                        {/* <p className="text-muted">{'item.caption'}</p> */}
                      </div>
                    </div>
                    <div className="py-4">
                      {item.id !== 1 && (item.t_resto[0]?.pack !== item.id || item.t_resto[0]?.pack_plan !== resolvePlanId()) && <div className="float-end plan-btn">
                        <Link
                          to="#"
                          className="btn btn-primary btn-sm waves-effect waves-light"
                          onClick={() => { upgradePlan(item.id) }}
                        >
                          {item.t_resto[0]?.pack_plan === resolvePlanId() ? t('Upgrade') : t('Switch')}
                        </Link>
                      </div>}
                      {currentPlan === 'monthly' && <h4>
                        <sup>
                          <small>MAD</small>
                        </sup>{" "}
                        {item.t_pack_price[0].price_month}/ <span className="font-size-16">{t('month')}</span>
                      </h4>}
                      {currentPlan === 'yearly' && <h4>
                        <sup>
                          <small>MAD</small>
                        </sup>{" "}
                        {item.t_pack_price[0].price_year}/ <span className="font-size-16">{t('year')}</span>
                      </h4>}
                    </div>
                    {/* <div className="plan-features mt-4">
                      <h5 className="text-center font-size-15 mb-4">
                        Plan Features :
                      </h5>
                      {item.isChild.map((subitem, key) => (
                        <p key={key}>
                          <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
                          {subitem.features}
                        </p>
                      ))}
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Packs));
