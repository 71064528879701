import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_COMMENT, ADD_NOTE, FETCH_DETAILS, FETCH_RESERVATIONS } from "./actionTypes"
import { setReservations, reservationSuccess, reservationError, setDetails } from "./actions"

//Include Both Helper File with needed methods
import { getDetails, getReservations, postComment, postNote } from "../../helpers/backend_helper"


function* fetchReservations({ payload: data }) {
  try {
    const response = yield call(getReservations, data)
    yield put(setReservations(response))
    yield put(reservationSuccess(response))
  } catch (error) {
    yield put(reservationError(error))
  }
}

function* fetchDetails({ payload: id }) {
  try {
    const response = yield call(getDetails, id)
    yield put(setDetails(response))
    yield put(reservationSuccess(response))
  } catch (error) {
    yield put(reservationError(error))
  }
}

function* addComment({ payload: data }) {
  try {
     yield call(postComment, data)
    const response = yield call(getDetails, data.resa)
    yield put(setDetails(response))
    yield put(reservationSuccess(response))
  } catch (error) {
    yield put(reservationError(error))
  }
}

function* addNote({ payload: data }) {
  try {
     yield call(postNote, data)
    const response = yield call(getDetails, data.resa)
    yield put(setDetails(response))
    yield put(reservationSuccess(response))
  } catch (error) {
    yield put(reservationError(error))
  }
}

export function* watchReservation() {
  yield takeEvery(FETCH_RESERVATIONS, fetchReservations)
  yield takeEvery(FETCH_DETAILS, fetchDetails)
  yield takeEvery(ADD_COMMENT, addComment)
  yield takeEvery(ADD_NOTE, addNote)

}

function* ReservationSaga() {
  yield all([fork(watchReservation)])
}

export default ReservationSaga;
