import React, { useState } from "react";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Switch from "react-switch";
import { editConfig, fetchResto } from "../../store/actions";
import { Button, Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Configuration = () => {
  const dispatch = useDispatch();
  const config = useSelector(state => state.restaurant.resto?.data);
  const [online, setOnline] = useState(false);
  const [offline, setOffline] = useState(false);
  const [MIAMS, setMIAMS] = useState(false);
  const [minBeforeCancel, setMinBeforeCancel] = useState(0);
  const [minDaysCancel, setMinDaysCancel] = useState(0);
  const [minHoursCancel, setMinHoursCancel] = useState(0);
  const [minMinCancel, setMinMinCancel] = useState(0);
  const [activeTab, setActiveTab] = useState('reservations')

  useEffect(() => {
      dispatch(fetchResto())
  }, [])

  useEffect(() => {
    if (config) {
      setOnline(config.resaOnline === '1' || config.resaOnline === true)
      setOffline(config.resaOffline === '1' || config.resaOffline === true)
      setMinBeforeCancel(config.cancelTime ? parseInt(config.cancelTime, 0) : 0)
      setMinDaysCancel(config.cancelDays ? parseInt(config.cancelDays, 0) : 0)
      setMinHoursCancel(config.cancelHours ? parseInt(config.cancelHours, 0) : 0)
      setMinMinCancel(config.cancelMinutes ? parseInt(config.cancelMinutes, 0) : 0)
      setMIAMS(config.miams === '1' || config.miams === true)
    }
  }, [config])

  const save = () => {
    const payload = {
      resaOnline: online ? '1' : '0',
      resaOffline: offline ? '1' : '0',
      cancelTime: minBeforeCancel,
      cancelDays: minDaysCancel,
      cancelHours: minHoursCancel,
      cancelMinutes: minMinCancel,
      miams: MIAMS ? '1' : '0',
    }
    dispatch(editConfig(payload))
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title={t("My restaurant")} breadcrumbItem={t("Configurations")} />
            <div className="d-flex align-items-center mb-2">
              <Button className="px-4 me-4" style={{ minWidth: 180 }} onClick={() => setActiveTab('reservations')} type="button" color={activeTab === 'reservations' ? 'primary' : 'light'}>
                <i className="ri-alarm-warning-line" />
                Reservations
              </Button>
              <Button className="px-4" style={{ minWidth: 180 }} onClick={() => setActiveTab('miams')} type="button" color={activeTab === 'miams' ? 'primary' : 'light'}>
                <i className="ri-coupon-line" />
                MIAMS
              </Button>
            </div>
            <Card>
              <CardBody>
                {activeTab === 'reservations' && <div>
                  <Row className="mb-4">
                    <Col className="m-auto" md={6}>
                      <h5>{t('Accept online reservations')}</h5>
                    </Col>
                    <Col className="m-auto" md={6}>
                      <Switch
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#43a047"
                        onChange={() => {
                          setOnline(!online);
                        }}
                        checked={online}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="m-auto" md={6}>
                      <h5>{t('Accept offline reservations')}</h5>
                    </Col>
                    <Col className="m-auto" md={6}>
                      <Switch
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#43a047"
                        onChange={() => {
                          setOffline(!offline);
                        }}
                        checked={offline}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="m-auto" md={6}>
                      <h5>{t('Time before restaurant cancels')}</h5>
                    </Col>
                    <Col className="m-auto d-flex align-items-center" md={6}>
                      <Button outline disabled={minBeforeCancel === 0} onClick={() => setMinBeforeCancel(minBeforeCancel - 5)} size="sm" type="button" color="primary">
                        <i className="mdi mdi-minus" />
                      </Button>
                      <h6 className="px-2 m-0">{minBeforeCancel} minutes</h6>
                      <Button outline onClick={() => setMinBeforeCancel(minBeforeCancel + 5)} size="sm" type="button" color="primary">
                        <i className="mdi mdi-plus" />
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="m-auto" md={6}>
                      <h5>{t('Time before client cancels')}</h5>
                    </Col>
                    <Col className="m-auto d-flex align-items-center" md={6}>
                      <div className="me-2 d-flex align-items-center">
                        <Button outline disabled={minDaysCancel === 0} onClick={() => setMinDaysCancel(minDaysCancel - 1)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-minus" />
                        </Button>
                        <h6 className="px-2 m-0">{minDaysCancel} {t('Days')}</h6>
                        <Button outline onClick={() => setMinDaysCancel(minDaysCancel + 1)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-plus" />
                        </Button>
                      </div>
                      <div className="me-2 d-flex align-items-center">
                        <Button outline disabled={minHoursCancel === 0} onClick={() => setMinHoursCancel(minHoursCancel - 1)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-minus" />
                        </Button>
                        <h6 className="px-2 m-0">{minHoursCancel} {t('Hours')}</h6>
                        <Button outline onClick={() => setMinHoursCancel(minHoursCancel + 1)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-plus" />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button outline disabled={minMinCancel === 0} onClick={() => setMinMinCancel(minMinCancel - 5)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-minus" />
                        </Button>
                        <h6 className="px-2 m-0">{minMinCancel} Minutes</h6>
                        <Button outline onClick={() => setMinMinCancel(minMinCancel + 5)} size="sm" type="button" color="primary">
                          <i className="mdi mdi-plus" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>}
                {activeTab === 'miams' && <div>
                  <Row>
                    <Col className="m-auto" md={6}>
                      <h5>{t('Accept')} MIAMS</h5>
                    </Col>
                    <Col className="m-auto" md={6}>
                      <Switch
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#43a047"
                        onChange={() => {
                          setMIAMS(!MIAMS);
                        }}
                        checked={MIAMS}
                      />
                    </Col>
                  </Row>
                </div>}
                <div className="d-flex justify-content-end border-top pt-3">
                  <Button onClick={() => save()} size="lg" type="button" color="primary">
                    {t('Save changes')}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
  )
}

export default withRouter(withTranslation()(Configuration));