import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { addRoom, editRoom, removeRoom } from "../../store/actions";

const Details = ({ info, shapes }) => {
    const [name, setName] = useState(null)
    const [tables, setTables] = useState([{ name: null, shape: 1 }])
    const room = useSelector(state => state.table.room);
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (info && room) {
            setName(room.name)
            const ts = room.tables?.map(el => ({ name: el.name, shape: el.table_form }))
            console.log(ts)
            return setTables(ts)
        }
        setName(info?.name || null)
        return setTables([{ name: null, shape: 1 }])
    }, [info, room])

    const toggleModal = () => {
        setModal(!modal)
    }

    const addRow = () => {
        const newTables = [...tables]
        newTables.push({ name: null, shape: 1 })
        setTables(newTables)
    }

    const deleteRow = index => {
        const newTables = [...tables.filter((_, idx) => index !== idx)]
        console.log(newTables, tables)
        setTables(newTables)
    }

    const setShape = (val, index) => {
        const newTables = [...tables]
        newTables[index].shape = val
        setTables(newTables);
    }

    const setTableName = (val, index) => {
        const newTables = [...tables]
        newTables[index].name = val
    }

    const action = () => {
        const payload = { name, tables: tables.map(el => ({ name: el.name, shape: el.shape })) }
        if (!info) return dispatch(addRoom(payload))
        payload.id = info?.room
        return dispatch(editRoom(payload))
    }

    const deleteRoom = () => {
        dispatch(removeRoom(info.room))
    }
    return (
        <>
            <FormGroup className="flex-grow-1">
                <Label for="table">Room name</Label>
                <Input placeholder="Room name" value={name} onChange={e => setName(e.target.value)} type="text" name="name" />
            </FormGroup>
            {tables.map((el, index) => (
                <Row key={index}>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="tables" size="sm">Table name</Label>
                            <Input placeholder="Table name" size="sm" value={el.name} onChange={e => setTableName(e.target.value, index)} type="text" name="name" />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup >
                            <Label for="tables" size="sm">Table shape</Label>
                            <div className="d-flex align-items-center">
                                <Input
                                    type="select"
                                    name="role"
                                    id="roleSelect"
                                    placeholder="Table shape"
                                    size="sm"
                                    value={el?.shape?.name}
                                    onChange={e => setShape(parseInt(e.target.value), index)}
                                    >
                                    {shapes.map(shape => (
                                        <option key={shape.id} value={shape.id} selected={shape.id === el.id}>{shape.name}</option>
                                    ))}
                                    </Input>
                                <button onClick={() => deleteRow(index)} type="button" className="btn btn-sm btn-outline-secondary ms-2">
                                    X
                                </button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            ))}
            <div className="d-flex justify-content-end mb-4">
                <button onClick={() => addRow()} type="button" className="btn btn-sm btn-success">
                    Add table
                </button>
            </div>
            <div className="d-flex justify-content-end py-3 border-top">
                <button disabled={!name} onClick={() => action()} type="button" className="btn btn-primary px-3">
                    {info ? 'Update' : 'Create'} room
                </button>
            </div>
            <Modal
              isOpen={modal}
              toggle={() => { toggleModal() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Delete room
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to delete <strong>{room?.name}</strong>?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button onClick={() => deleteRoom()} type="submit" class="btn btn-danger px-4">Yes</button>
                <button onClick={() => setModal(false)} type="submit" class="btn btn-secondary px-4">No</button>
              </div>
            </Modal>
        </>
    )
}

export default Details;