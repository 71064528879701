export const FETCH_MENUS = "FETCH_MENUS";
export const SET_MENUS = "SET_MENUS";
export const FETCH_MENU = "FETCH_MENU";
export const SET_MENU = "SET_MENU";
export const ADD_MENU = "ADD_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const MOVE_MENU = "MOVE_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const MOVE_CATEGORY = "MOVE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_ITEM = "ADD_ITEM";
export const EDIT_ITEM = "EDIT_ITEM";
export const MOVE_ITEM = "MOVE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const MENU_SUCCESS = "MENU_SUCCESS";
export const MENU_ERROR = "MENU_ERROR";


