import { EDIT_PACK, FETCH_PACKS, PACK_ERROR, PACK_SUCCESS, SET_PACKS } from "./actionTypes";

const initialState = {
  data: [],
  loading: false,
  error: "",
  success: "",
};

const pack = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PACKS:
      state = { ...state, loading: true };
      break;
    case SET_PACKS:
      state = { ...state, loading: false, data: action.payload };
      break;
    case EDIT_PACK:
      state = { ...state, loading: true };
      break;
    case PACK_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case PACK_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default pack;
