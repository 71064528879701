import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, FETCH_PROFILE, EDIT_EMAIL, EDIT_PASSWORD } from "./actionTypes"
import { profileSuccess, profileError, setProfile } from "./actions"

//Include Both Helper File with needed methods
import {
  getProfile,
  putProfile,
  updateEmail,
  updatePassword,
} from "../../../helpers/backend_helper"


function* fetchProfile() {
  try {
    const response = yield call(getProfile)
    yield put(setProfile(response))
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(putProfile, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone,
      data: {
        genre: user.gender,
      },
    })
    const authUser = localStorage.getItem("authUser");
    const userObject = JSON.parse(authUser);
    // Override the first name and last name
    if (user.firstname) userObject.firstname = user.firstname;
    if (user.lastname) userObject.lastname = user.lastname;
    if (user.email) userObject.email = user.email;
    if (user.phone) userObject.phone = user.phone;
    
    localStorage.setItem("authUser", JSON.stringify(userObject));
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

function* editEmail({ payload: { user } }) {
  try {
    const response = yield call(updateEmail, {
      currentMail: user.currentEmail,
      newMail: user.newEmail,
      confirmMail: user.confirmEmail,
    })
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

function* editPassword({ payload: { user } }) {
  try {
    const response = yield call(updatePassword, {
      currentPass: user.currentPassword,
      newPass: user.newPassword,
      confirmPass: user.confirmPassword,
    })
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(FETCH_PROFILE, fetchProfile)
  yield takeEvery(EDIT_EMAIL, editEmail)
  yield takeEvery(EDIT_PASSWORD, editPassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga;
