import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Details = () => {
    const details = useSelector(state => state.reservation.details);
    const [client, setClient] = useState({});

    useEffect(() => {
    if (details.data?.first_name) return setClient({ firstname: details.data?.first_name, lastname: details.data?.last_name, phone: details.data?.phone, collect_code: details.data?.collect_code })
    return setClient(details.t_user);
    }, [details])

    return (
        <Row>
            {details.t_user?.role === 8 && <Col sm={12} xl={6}>
                <Card>
                    <CardBody className="bg-light">
                        <div className="d-flex align-items-start">
                            <img
                                className="border border-primary border-1 rounded me-2"
                                width={90}
                                height={90}
                                src={'https://cdn3.iconfinder.com/data/icons/travel-filled-line-2/550/butler-256.png'}
                                alt="Client avatar"
                            />
                            <div>
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <h5 className="m-0 me-2">{details.t_user?.firstname} {details.t_user?.lastname}</h5>
                                    <span className="badge p-1 mb-2 bg-info px-2">
                                        {'Butler'}
                                    </span>
                                </div>
                                {details.t_user?.phone && <span className="d-block d-flex align-items-center"><i className="ri-phone-line me-2" /> {details.t_user?.phone}</span>}
                                {details.t_user?.email && <span className="d-block d-flex align-items-center"><i className="ri-mail-line me-2" /> {details.t_user?.email}</span>}
                                {/* <p>{details.t_user.city} {details.t_user.zipcode}</p> */}
                                {(details.t_user?.t_address?.city || details.t_user?.t_address?.zipcode) && <p className="d-flex align-items-center mb-0"><i className="ri-building-line me-2" />{details.t_user?.t_address?.city} {details.t_user?.t_address?.zipcode}</p>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>}
            <Col sm={12} xl={6}>
                <Card>
                    <CardBody className="bg-light">
                        <div className="d-flex align-items-start">
                            <img
                                className="border border-primary border-1 rounded me-2"
                                width={90}
                                height={90}
                                src={'https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg'}
                                alt="Client avatar"
                            />
                            <div>
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <h5 className="m-0 me-2">{client?.firstname} {client?.lastname}</h5>
                                    <span className="badge p-1 mb-2 bg-primary px-2">
                                        {t('Client')}
                                    </span>
                                </div>
                                {client?.phone && <span className="d-block d-flex align-items-center"><i className="ri-phone-line me-2" /> {client?.phone}</span>}
                                {client?.email && <span className="d-block d-flex align-items-center"><i className="ri-mail-line me-2" /> {client?.email}</span>}
                                {/* <p>{details.t_user.city} {details.t_user.zipcode}</p> */}
                                {(client?.city || client?.zipcode) && <p>{client?.city} {client?.zipcode}</p>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm={12} xl={12}>
                <Card>
                    <CardBody className="bg-light">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5># {details.ref}</h5>
                            <div>
                                <span className="badge p-1 mb-2" style={{ backgroundColor: details.t_status_items?.color }}>
                                    <i className={`mdi me-1 ${details.t_status_items?.iconName}`} />
                                    {details.t_status_items?.name}
                                </span>
                                {details.r_resa_tag?.length > 0 && details.r_resa_tag.map(el => (
                                    <span className="badge p-1 mb-2 ms-1 bg-seconday">
                                        <i className={`mdi me-1 ${details.t_tag_items?.iconName}`} />
                                        {el.t_tag_items?.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h6 className="d-flex align-items-center"><i className="ri ri-restaurant-line me-2" /> <strong>{details.nb_couvert}</strong> covers</h6>
                            <h6 className="d-flex align-items-center"><i className="ri ri-calendar-line me-2" /><strong className="me-1">{details.resa_date}</strong><i className="ri-time-line me-1" /><strong>{details.resa_hour}</strong></h6>
                            <h6 className="d-flex align-items-center"><i className="ri ri-passport-line me-2" /> Source: <strong>{details.reservationType}</strong></h6>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={12}>
                <Card>
                    <CardBody className="bg-light">
                    <CardTitle className="mb-2">Notes</CardTitle>
                        <span>{details.note || `${t('No notes yet')}..`} </span>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default withRouter(withTranslation()(Details));
