import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccordionBody, AccordionHeader, AccordionItem, Card, Col, FormGroup, Input, Label, Modal, Row, UncontrolledAccordion } from "reactstrap";
import { addMenu, editMenu, moveCategory, moveItem, removeMenu } from "../../store/menu/actions";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CatItem from "./CatItem";

const Details = ({ info }) => {
    const [name, setName] = useState(null)
    const [avr, setAvr] = useState(null);
    const [categories, setCategories] = useState([{ name: null, elements: [{ name: '', price: '', description: '' }] }])
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (info) {
            setName(info.name)
            setAvr(info.avrg_price)
            const ms = info.t_menu_category?.map(el => ({
                id: el.id,
                name: el.name,
                elements: el.t_menu_item
            }))
            return setCategories(ms)
        }
        setName(info?.name || null)
        return setCategories([{ name: null, elements: [{ name: '', price: '', description: '' }] }])
    }, [info])

    const addRow = () => {
        const newCats = [...categories]
        newCats.unshift({ name: '', elements: [{ name: '', price: '', description: '' }] })
        setCategories(newCats)
    }

    const addElRow = index => {
        const newCats = [...categories]
        if (newCats[index].elements) newCats[index].elements?.push({ name: '', price: '', description: '' })
        else newCats[index].elements = [{ name: '', price: '', description: '' }]

        setCategories(newCats)
    }

    const deleteCategory = index => {
        const newCats = [...categories.filter((_, idx) => index !== idx)]
        setCategories(newCats)
    }

    const deleteElement = (categoryIndex, elementIndex) => {
        const newCats = categories.map((category, idx) => {
            if (idx === categoryIndex) {
                return {
                    ...category, 
                    elements: category.elements.filter((_, idx) => idx !== elementIndex)
                };
            }
            return category;
        });        
        setCategories(newCats);
    };

    // const setShape = (val, index) => {
    //     const newTables = [...tables]
    //     newTables[index].shape = val
    //     setTables(newTables);
    // }

    const setCategoryName = (val, index) => {
        const newCats = [...categories]
        newCats[index].name = val
        setCategories(newCats)
    }

    const setElementName = (val, index, idx) => {
        const newCats = [...categories]
        newCats[index].elements[idx] = val
        setCategories(newCats)
    }

    const action = () => {
        const payload = { name, categories: categories.map(el => ({ name: el.name, elements: el.elements })) }
        if (!info) return dispatch(addMenu(payload))
        payload.id = info?.id
        return dispatch(editMenu(payload))
    }

    const deleteMenu = () => {
        dispatch(removeMenu(info.id))
    }

    const moveCat = (dragIndex, hoverIndex) => {
        const elements = categories
        const draggedEl = elements[dragIndex];
        const updatedEl = [...elements];
        updatedEl.splice(dragIndex, 1);
        updatedEl.splice(hoverIndex, 0, draggedEl);
        setCategories(updatedEl)
        const payload = updatedEl.map(el => ({ id: el.id }))
        dispatch(moveCategory(payload))
    };

    const moveEl = (dragIndex, hoverIndex, catIndex) => {
        // Copy the categories to avoid mutating the state directly
        const updatedCategories = [...categories];
    
        // Get the elements array of the specific category
        const elements = [...updatedCategories[catIndex].elements];
        
        // Get the element to be moved
        const draggedEl = elements[dragIndex];
    
        // Remove the dragged element from its original position
        elements.splice(dragIndex, 1);
    
        // Insert the dragged element at the hover position
        elements.splice(hoverIndex, 0, draggedEl);
    
        // Update the specific category with the modified elements array
        updatedCategories[catIndex] = {
            ...updatedCategories[catIndex],
            elements
        };
    
        // Update the state with the modified categories array
        setCategories(updatedCategories);
        const payload = elements.map(el => ({ id: el.id }))
        dispatch(moveItem(payload))
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <>
                    {categories.map((cat, index) => (
                        <CatItem
                            key={index}
                            cat={cat}
                            index={index}
                            moveCat={moveCat}
                            setCategoryName={setCategoryName}
                            addRow={addRow}
                            deleteCategory={deleteCategory}
                            moveEl={moveEl}
                            setElementName={setElementName}
                            addElRow={addElRow}
                        />
                    ))}
                </>
            </DndProvider>

            {/* <div className="d-flex justify-content-end py-3 border-top">
                <button disabled={!name} onClick={() => action()} type="button" className="btn btn-primary px-3">
                    {info ? 'Update' : 'Create'} menu
                </button>
            </div> */}
        </>
    )
}

export default Details;
