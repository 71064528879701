import { ADD_ROOM, ADD_TABLE, DELETE_ROOM, DELETE_TABLE, EDIT_ROOM, EDIT_TABLE, FETCH_ROOM, FETCH_ROOMS, FETCH_TABLES, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes"

export const fetchTables = data => {
  return {
    type: FETCH_TABLES,
    payload: data
  }
}

export const setTables = data => {
  return {
    type: SET_TABLES,
    payload: data,
  }
}

export const fetchRooms = data => {
  return {
    type: FETCH_ROOMS,
    payload: data
  }
}

export const fetchRoom = data => {
  return {
    type: FETCH_ROOM,
    payload: data
  }
}

export const setRooms = data => {
  return {
    type: SET_ROOMS,
    payload: data,
  }
}

export const setRoom = id => {
  return {
    type: SET_ROOM,
    payload: id,
  }
}

export const addRoom = data => {
  return {
    type: ADD_ROOM,
    payload: data,
  }
}

export const editRoom = data => {
  return {
    type: EDIT_ROOM,
    payload: data,
  }
}
export const addTable = data => {
  return {
    type: ADD_TABLE,
    payload: data,
  }
}

export const editTable = data => {
  return {
    type: EDIT_TABLE,
    payload: data,
  }
}

export const removeRoom = id => {
  return {
    type: DELETE_ROOM,
    payload: id,
  }
}

export const removeTable = id => {
  return {
    type: DELETE_TABLE,
    payload: id,
  }
}

export const tableSuccess = msg => {
  return {
    type: TABLE_SUCCESS,
    payload: msg,
  }
}

export const tableError = error => {
  return {
    type: TABLE_ERROR,
    payload: error,
  }
}
