const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Calendar",
        icon: "mdi mdi-calendar-outline",
        url: "/calendar",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Profile",
        icon: "mdi mdi-account-outline",
        url: "/userprofile",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "My restaurant",
        icon: "mdi mdi-silverware-variant",
        subItem: [
            { sublabel: "Information", link: "/resto-info" },
            { sublabel: "Reservations", link: "/resto-reservation" },
            { sublabel: "Configurations", link: "/resto-config" },
            { sublabel: "Tables", link: "/tables" },
            { sublabel: "Menus", link: "/resto-menus" },
            { sublabel: "Promotions", link: "/promotions" },
            { sublabel: "Images", link: "/images" },
            { sublabel: "Packs", link: "/resto-pack" },
        ],
    },
    {
        label: "Invoices",
        icon: "mdi mdi-file-document-outline",
        url: "/invoices",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "My documents",
        icon: "mdi mdi-file-check-outline",
        url: "/contracts",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Rapports",
        icon: "mdi mdi-chart-areaspline",
        url: "/rapports",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
    {
        label: "External integrations",
        icon: "mdi mdi-link",
        url: "/external-integration",
        isHasArrow: true,
        bgcolor: "bg-primary",
    },
]
export default SidebarData;