import { ADD_INVOICE, DELETE_INVOICE, EDIT_INVOICE, FETCH_INVOICE, FETCH_INVOICES, INVOICE_ERROR, INVOICE_SUCCESS, SET_INVOICE, SET_INVOICES } from "./actionTypes"

export const fetchInvoices = data => {
  return {
    type: FETCH_INVOICES,
    payload: data
  }
}

export const setInvoices = data => {
  return {
    type: SET_INVOICES,
    payload: data,
  }
}

export const fetchInvoice = data => {
  return {
    type: FETCH_INVOICE,
    payload: data
  }
}

export const setInvoice = data => {
  return {
    type: SET_INVOICE,
    payload: data,
  }
}

export const addInvoice = data => {
  return {
    type: ADD_INVOICE,
    payload: data,
  }
}

export const editInvoice = data => {
  return {
    type: EDIT_INVOICE,
    payload: data,
  }
}

export const removeInvoice = id => {
  return {
    type: DELETE_INVOICE,
    payload: id,
  }
}

export const invoiceSuccess = msg => {
  return {
    type: INVOICE_SUCCESS,
    payload: msg,
  }
}

export const invoiceError = error => {
  return {
    type: INVOICE_ERROR,
    payload: error,
  }
}
