import { Card, CardBody, CardHeader, FormGroup, Input, Label, Modal } from "reactstrap";
import Details from "./details";
import { useDrag, useDrop } from "react-dnd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addCategory, editMenu, removeMenu } from "../../store/actions";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
const ItemType = "BOX";

const MenuItem = ({ el, index, moveMenu }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [menuModal, setMenuModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [catModal, setCatModal] = useState(false);
  const [name, setName] = useState(null);
  const [catName, setCatName] = useState(null);
  const [avrPrice, setAvrPrice] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (el) {
      setName(el.name)
      setAvrPrice(el.avrg_price)
    }
  }, [el])
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        // moveMenu(draggedItem.index, index, false);
      }
    },
    drop: (draggedItem) => {
      console.log(draggedItem, index)
      moveMenu(draggedItem.index, index, true);
      draggedItem.index = index;
    },
  });
  const toggleMenuModal = () => {
    setMenuModal(!menuModal)
  }

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const toggleCatModal = () => {
    setCatModal(!catModal)
  }

  const updateMenu = () => {
    dispatch(editMenu({ id: el.id, name, avrg_price: avrPrice }))
    setMenuModal(false)
  }

  const deleteMenu = () => {
    dispatch(removeMenu(el.id))
    setDeleteModal(false)
  }

  const createCategory = () => {
    dispatch(addCategory({ name: catName, id: el.id }))
    setShowDetails(true)
    setCatName(null)
    setCatModal(false)
  }
   return (
    <div
      className="card"
      style={{
      opacity: isDragging ? 0.5 : 1,
      }}
    >
      <CardHeader className="d-flex align-items-center shadow">
          <span ref={(node) => drag(drop(node))} style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }}>
              <b>☰</b>
          </span>
          <div className="flex-grow-1 d-flex row">
            <b className="col-4">{el.name}</b>
            <span className="col-4 ms-4">{el.avrg_price} {t('Average price')}</span>
          </div>
          <button onClick={() => {console.log(el); setShowDetails(!showDetails)}} className="btn btn-sm btn-outline-secondary me-2 text-nowrap"><i className={`ri-arrow-${showDetails ? 'up' : 'down'}-s-line align-bottom`}></i></button>
          <button onClick={() => toggleMenuModal(el)} className="btn btn-sm btn-outline-secondary me-2 text-nowrap"><i className="ri-edit-line align-bottom"></i></button>
          <button onClick={() => toggleCatModal(el)} className="btn btn-sm btn-outline-success me-2 text-nowrap"><i className="ri-add-line align-bottom"></i></button>
          <button disabled={el.t_menu_category?.length > 0} onClick={() => toggleDeleteModal(el)} className="btn btn-sm btn-outline-danger me-2 shadow text-nowrap"><i className="ri-delete-bin-line align-bottom"></i></button>
      </CardHeader>
      {showDetails && <CardBody>
          <Details info={el} isDetails={true} />
          {el.t_menu_category?.length === 0 && <span className="d-flex justify-content-center">{t('This menu is empty')} <Link className="ms-1" onClick={() => toggleCatModal()}>{t('Add category')}</Link></span>}
      </CardBody>}

      <Modal
        isOpen={menuModal}
        toggle={() => { toggleMenuModal() }}
        centered
        >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
              {t('Edit menu')}
              </h5>
              <button
                type="button"
                onClick={() => { setMenuModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Menu name')}</Label>
                  <Input placeholder={t("Menu name")} value={name} onChange={e => setName(e.target.value)} type="text" name="name" />
              </FormGroup>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Average price')}</Label>
                  <Input placeholder={t("average price")} value={avrPrice} onChange={e => setAvrPrice(e.target.value)} type="text" name="name" />
              </FormGroup>
          </div>
          <div className="modal-footer d-flex justify-content-end">
              <button onClick={() => setMenuModal(false)} type="submit" class="btn btn-secondary px-4">{t('Close')}</button>
              <button onClick={() => updateMenu()} type="submit" class="btn btn-primary px-4">{t('Update')}</button>
          </div>
      </Modal>
      <Modal
        isOpen={catModal}
        toggle={() => { toggleCatModal() }}
        centered
        >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
              {t("Add category")}
              </h5>
              <button
                type="button"
                onClick={() => { setCatModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Name')}</Label>
                  <Input placeholder={t("Category name")} value={catName} onChange={e => setCatName(e.target.value)} type="text" name="name" />
              </FormGroup>
          </div>
          <div className="modal-footer d-flex justify-content-end">
              <button onClick={() => setCatModal(false)} type="submit" class="btn btn-secondary px-4">{t('Close')}</button>
              <button onClick={() => createCategory()} type="submit" class="btn btn-primary px-4">{t('Add')}</button>
          </div>
      </Modal>

      <Modal
        isOpen={deleteModal}
        toggle={() => { toggleDeleteModal() }}
        centered
      >
        <div className="modal-header">
            <h5 className="modal-title mt-0">
              {t('Delete category')}
            </h5>
            <button
              type="button"
              onClick={() => { setDeleteModal(false) }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
          <h5>{t('Do you want to delete')} <strong>{el?.name}</strong>?</h5>
        </div>
        <div className="modal-footer d-flex justify-content-center">
            <button onClick={() => deleteMenu()} type="submit" class="btn btn-danger px-4">{t('Yes')}</button>
            <button onClick={() => setDeleteModal(false)} type="submit" class="btn btn-secondary px-4">{t('No')}</button>
        </div>
      </Modal>
    </div>
  )
}

export default withRouter(withTranslation()(MenuItem));