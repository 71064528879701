import React, { useEffect, useState } from "react";

//SimpleBar
import SimpleBar from "simplebar-react";


import { Card, Row, Col, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../store/actions";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const Comments = () => {
  const comments = useSelector(state => state.reservation.details)
  const [user, setUser] = useState(null);
  const [comment, setComment] = useState(null);
  const [fComments, setFComments] = useState([]);

  const dispatch = useDispatch();

  const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser");
    if (user) return JSON.parse(user);
    return null;
  };
  useEffect(() => {
    const user = getLoggedInUser()
    setUser(user);
    setFComments(comments)
  }, [comments])

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    
    return date.toLocaleString('en-US', options);
  }

  const sendComment = () => {
    dispatch(addComment({
      resa: comments.id,
      user: user.id,
      comment,
    }))
  }

return (
  <Card>
    <Row>
      <Col lg={12}>
        <div className="h-100 w-100">
          <SimpleBar className="scrollable py-2" style={{ height: 270 }}>
            {fComments.t_resa_comment?.map(el => (
              <div key={el.id} className={`d-block d-flex`}>
                  <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-xs">
                        <span className="avatar-title bg-primary-subtle rounded-circle text-primary">
                          {/* <i className="ri-user-line"></i> */}
                          {el.t_user?.fullname?.split('')[0]?.toUpperCase()}{el.t_user?.fullname?.split(' ')[1]?.split('')[0]?.toUpperCase()}
                        </span>
                      </div>
                  </div>
                  <div>
                    <p className="mb-1">{el.t_user?.fullname}</p>
                    <div className="rounded bg-primary px-3 py-1">
                      <p className="fon-size-12 text-light m-0 rounded">{el.comment}</p>
                      <p className="font-size-12 text-light m-0">{formatDate(el.created)}</p>
                    </div>
                  </div>
              </div>
            ))}
            {fComments?.t_resa_comment?.length === 0 && <h5 className="text-secondary">{t('No comments yet')}..</h5>}
          </SimpleBar>
          
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendComment();
                return false;
            }}
              className="d-none d-lg-block pt-3 flex-grow-1"
            >
              <div className="position-relative">
                <Input
                  type="text"
                  className="ps-4 bg-light"
                  placeholder={`${t('Type a comment')}...`}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                />
                <i className="ri-send-plane-2-line text-secondary position-absolute top-0 mt-2 ms-1"></i>
              </div>
            </form>
        </div>
      </Col>
    </Row>
</Card>
);
};
export default withRouter(withTranslation()(Comments));
