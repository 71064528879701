import React from "react";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ExtButton from "./extButton";
import Cover from "./cover";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const External = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={t("Mymeal")} breadcrumbItem={t("External links")} />
          <Row>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>Button</CardTitle>
                        <ExtButton />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle>{t('Cover')}</CardTitle>
                        <Cover />
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(External));

