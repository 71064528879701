import React, { useEffect, useState } from "react";

import { Card, CardBody, CardText, CardTitle, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";

//Import Breadcrumb
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editRestoAddress, editRestoContact, editRestoInfo } from "../../store/actions";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const RestoCard = ({ title, info, notEditable, type }) => {
  const dispatch = useDispatch();

  const [modalResto, setModalResto] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [enDesc, setEnDesc] = useState('');
  const [active, setActive] = useState('fr');
  
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');

  const updateResto = () => {
    dispatch(editRestoInfo({ id: info.id, name, description: desc, enDesc: enDesc }))
    if (enDesc) dispatch(editRestoContact({ id: info.id, firstname, lastname, email, phone, en_description: enDesc }))
    return setModalResto(false)
  }
  const updateContact = () => {
    dispatch(editRestoContact({ id: info.id, firstname, lastname, email, phone }))
    return setModalContact(false)
  }

  const updateAddress = () => {
    dispatch(editRestoAddress({ id: info.id, line1, line2, zipcode, city }))
    return setModalAddress(false)
  }

  const toggleModal = () => {
    if (type === 'info') {
      setName(info.name)
      setDesc(info.fr_description)
      setEnDesc(info.en_description)
      return setModalResto(!modalResto);
    }
    if (type === 'address') {
      setLine1(info.line1)
      setLine2(info.line2)
      setZipcode(info.zipcode)
      setCity(info.city)
      return setModalAddress(!modalAddress);
    }
    if (type === 'contact') {
      setFirstname(info[t('First name')])
      setLastname(info[t('Last name')])
      setPhone(info[t('Phone')])
      setEmail(info[t('Email')])
      return setModalContact(!modalContact);
    } 
  }
  return (
    <React.Fragment>
      <Row>
        <Col sm={12} xl={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="mt-0">
                  {title}
                </CardTitle>
                {!notEditable && <Link onClick={() => { toggleModal() }} to="#" className="btn btn-sm btn-soft-primary ms-2"><i className="ri-edit-line me-1"></i>{t('Edit')}</Link>}
              </div>
              {Object.entries(info).map(([key, value]) => (
                <div key={key}>
                  {key !== 'id' && key !== 'en_description' && key !== 'fr_description' && <div key={key} className="d-flex align-items-start mb-2">
                    <strong className="mx-2">{`${key.charAt(0).toUpperCase()}${key.slice(1)}:`}</strong>
                    <CardText>{value?.toString().length > 100 ? value.slice(0, 100) + '...' :  value}</CardText>
                  </div>}
                </div>
              ))}
            </CardBody>
            </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modalResto}
        toggle={() => { toggleModal() }}
        centered
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {t('My restaurant')}
          </h5>
          <button
            type="button"
            onClick={() => { setModalResto(false) }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateResto();
            return false;
          }}
        >
          <FormGroup>
            <Label for="exampleText">{t('Name')}</Label>
            <Input value={name} onChange={e => setName(e.target.value)} type="text" name="text" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Description</Label>
            <div className="mb-2">
              <a onClick={() => setActive('fr')} className={`btn me-2 btn-outline-${active === 'fr' ? 'primary' : 'secondary' }`}>{t('French')}</a>
              <a onClick={() => setActive('en')} className={`btn btn-outline-${active === 'en' ? 'primary' : 'secondary' }`}>{t('English')}</a>
            </div>
            {active === 'fr' && <Input value={desc} placeholder="Description" onChange={e => setDesc(e.target.value)} type="textarea" name="text" rows="8" />}
            {active === 'en' && <Input value={enDesc} placeholder="Description" onChange={e => setEnDesc(e.target.value)} type="textarea" name="text" rows="8" />}
          </FormGroup>
          <button disabled={!name} type="submit" class="btn btn-primary mt-4">{t('Save changes')}</button>
        </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalContact}
        toggle={() => { toggleModal() }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {t('Contact')}
          </h5>
          <button
            type="button"
            onClick={() => { setModalContact(false) }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateContact();
            return false;
          }}
        >
          <FormGroup>
            <Label for="exampleText">{t('First name')}</Label>
            <Input value={firstname} onChange={e => setFirstname(e.target.value)} type="text" name="text" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">{t('Last name')}</Label>
            <Input value={lastname} onChange={e => setLastname(e.target.value)} type="text" name="text" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">{t('Phone')}</Label>
            <Input value={phone} onChange={e => setPhone(e.target.value)} type="text" name="text" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">{t('Email')}</Label>
            <Input value={email} onChange={e => setEmail(e.target.value)} type="email" name="text" />
          </FormGroup>
          <button type="submit" class="btn btn-primary mt-4">{t('Save changes')}</button>
        </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalAddress}
        toggle={() => { toggleModal() }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Address
          </h5>
          <button
            type="button"
            onClick={() => { setModalAddress(false) }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateAddress();
            return false;
          }}
        >
          <FormGroup>
            <Label for="exampleText">Line 1</Label>
            <Input value={line1} onChange={e => setLine1(e.target.value)} type="text" name="line1" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Line 2</Label>
            <Input value={line2} onChange={e => setLine2(e.target.value)} type="text" name="line2" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">{t('Zip code')}</Label>
            <Input value={zipcode} onChange={e => setLine2(e.target.value)} type="text" name="zipcode" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">{t('City')}</Label>
            <Input value={city} onChange={e => setCity(e.target.value)} type="text" name="city" />
          </FormGroup>
          <button type="submit" class="btn btn-primary mt-4">{t('Save changes')}</button>
        </form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(RestoCard));
