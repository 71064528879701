import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";
import authSaga from "./auth/login/saga";
import forgetPasswordSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga";
import RestoSaga from "./resto/saga";
import ReservationSaga from "./reservation/saga";
import TableSaga from "./table/saga";
import MenuSaga from "./menu/saga";
import InvoiceSaga from "./invoice/saga";
import PromotionSaga from "./promotion/saga";
import ImageSaga from "./image/saga";
import ContractSaga from "./contract/saga";
import calendarSaga from "./calendar/saga";
import PackSaga from "./pack/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(authSaga),
    fork(forgetPasswordSaga),
    fork(ProfileSaga),
    fork(RestoSaga),
    fork(ReservationSaga),
    fork(TableSaga),
    fork(MenuSaga),
    fork(InvoiceSaga),
    fork(PromotionSaga),
    fork(ImageSaga),
    fork(ContractSaga),
    fork(calendarSaga),
    fork(PackSaga),
  ]);
}
