import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import { addRoom, addTable, editRoom, editTable, fetchRoom, fetchRooms, fetchTables, removeRoom, removeTable } from '../../store/table/actions';
import Details from './details';
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Table = () => {
  // Modals
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [roomNameModal, setroomNameModal] = useState(false);
  const [tableModal, setTableModal] = useState(false);

  const [tableName, setTableName] = useState(false);
  const [tableShape, setTableShape] = useState('0');

  const [roomName, setRoomName] = useState(null);
  const [current, setCurrent] = useState(null);
  const [currentTable, setCurrentTable] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const tables = useSelector(state => state.table?.data || [])
  const rooms = useSelector(state => state.table?.rooms || [])
  const loading = useSelector(state => state.table?.loading || false)
  const [sOpen, setSOpen] = useState(false);
  const [sOpen1, setSOpen1] = useState(false);
  const [fTables, setFTables] = useState([]);
  const [covers, setCovers] = useState('')
  const [maxCovers, setMaxCovers] = useState('')
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);
  const [room, setRoom] = useState({ name: 'All rooms', id: null })
  const [shape, setShape] = useState({ name: 'All shapes', icon: 'mdi-shape-outline', id: null })

  const shapes = [
    { name: 'Square', icon: 'mdi mdi-checkbox-blank-outline', id: 0 },
    { name: 'Circle', icon: 'mdi mdi-checkbox-blank-circle-outline', id: 1 },
    // { name: 'Triangle', icon: 'mdi-triangle-outline', id: 1 },
    // { name: 'Rectangle', icon: 'mdi-rectangle-outline', id: 3 },
    // { name: 'Pentagon', icon: 'mdi-pentagon-outline', id: 4 },
    // { name: 'Hexagon', icon: 'mdi-hexagon-outline', id: 5 },
    // { name: 'Octagon', icon: 'mdi-octagon-outline', id: 6 },
  ]

  const toggleModal = data => {
    if (data) dispatch(fetchRoom(data?.room || data?.id))
    setCurrent(data || null)
    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data || null)
    return setModal1(!modal1);
  }

  const toggleModal2 = data => {
    setCurrent(data || null)
    return setModal2(!modal2);
  }

  useEffect(() => {
    if (rooms && rooms.length > 0) setRoom(rooms[1])
  }, [rooms])

  useEffect(() => {
    dispatch(fetchTables({ search: q, shape_id: shape.id, room_id: room?.id }))
  }, [])

  useEffect(() => {
    dispatch(fetchRooms())
  }, [])

  useEffect(() => {
    if (tables) {
      if (q) {
        const filteredTables = fTables.filter(el => {
          const fullName = `${el.name} ${el.t_room?.name}`.toString().toLowerCase();
          return fullName.includes(q.toLowerCase())
        })
        setFTables(filteredTables) 
      }
      else if (room?.id && tables.length > 0) {
        const filteredTables = tables?.filter(el => el.room === room.id)
        setFTables(filteredTables)
      }
      else if (shape?.id && tables.length > 0) {
        const filteredTables = tables?.filter(el => el.table_form === shape.id)
        setFTables(filteredTables)
      }
      else setFTables(tables)
    }
    else setFTables(tables)
  }, [tables, q, room, shape])

  const getTableShape = shape => {
    return shapes.find(el => el.id === shape)
  }

  const deleteTable = id => {
    dispatch(removeTable(id))
    setModal1(false)
  }

  const toggleRoomName = data => {
    setCurrent(data || null)
    setRoomName(data?.name || null)
    setroomNameModal(true)
  }
  const toggleTable = (room, table) => {
    setCurrent(room)
    setCurrentTable(table || null)
    setTableName(table?.name || null)
    setTableShape(table?.shape_id)
    setCovers(table?.nb_couvert)
    setMaxCovers(table?.max_nb_couvert)
    setTableModal(true)
  }

  const createRoom = data => {
    setCurrent(data || null)
    const payload = { name: roomName }
    if (!data) dispatch(addRoom(payload))
    if (data) {
      payload.id = data?.id
      dispatch(editRoom(payload))
    }
    setroomNameModal(false)
  }

  const createTable = data => {
    const payload = {
      room: current?.id,
      name: tableName,
      table_form: tableShape === '0' ? 0 : 1,
      nb_couvert: covers,
      max_nb_couvert: maxCovers,
    }
    if (!data) dispatch(addTable(payload))
    if (data) {
      payload.id = data?.id
      dispatch(editTable(payload))
    }
    setTableModal(false)
  }

  const deleteRoom = id => {
    dispatch(removeRoom(id))
    setModal2(false)
  }

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title={t("My restaurant")} breadcrumbItem="Tables" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row className='mb-3'>
                <Col md={6} className='d-flex'>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-end">
                      <button onClick={() => toggleRoomName()} type="button" className="btn btn-primary text-nowrap">
                        <i className="ri-add-line align-bottom me-1"></i>
                        {t('Add room')}
                      </button>
                  </div>
                </Col>
            </Row>
            <div>
              {rooms.map((el, index) => (
                <Card>
                  <CardHeader className='shadow border rounded'>
                    <div className="d-flex align-items-center justify-content-between flex-wrap" style={{ width: '100%' }}>
                      <b className="flex-grow-1 d-flex align-items-center">
                        <i class="ri-lg ri-door-lock-box-line me-1"></i>
                        {el.name}
                      </b>
                        <button onClick={() => {toggleTable(el)}} type="button" className="btn btn-sm btn-outline-secondary text-nowrap me-2">
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                        <button onClick={() => toggleRoomName(el)} type="button" className="btn btn-sm btn-outline-secondary text-nowrap me-2">
                          <i className="ri-edit-line align-bottom"></i>
                        </button>
                        <button disabled={el.tables?.length > 0} onClick={() => toggleModal2(el)} type="button" className="btn btn-sm btn-danger text-nowrap me-2">
                          <i className="ri-delete-bin-line align-bottom"></i>
                        </button>
                    </div>
                  </CardHeader>
                  <CardBody accordionId={index + 1}>
                    {!el.tables || el.tables.length === 0 && <h6 className="text-center mt-2 mb-0">{t('No tables in this room')}</h6>}
                    {el.tables && el.tables.length > 0 && <Row>
                        <Col cols={3} className='my-auto'>
                          <Label for="name" className="d-block">Name</Label>
                        </Col>
                        <Col cols={3} className='my-auto'>
                          <Label for="shape" className="d-block">{t('Shape')}</Label>
                        </Col>
                        <Col cols={3} className='my-auto'>
                          <Label for="couvers" className="d-block">{t('Covers')}</Label>
                        </Col>
                        <Col cols={3} className='my-auto'>
                          <Label for="actions" className="d-block">Actions</Label>
                        </Col>
                    </Row>}
                    <div style={{ maxHeight: 200, overflow: 'scroll' }}>
                      {el.tables?.map((table, index) => (
                          <Row className='mb-2'>
                            <Col cols={3} className='my-auto'>
                              <b>{table.name}</b>
                            </Col>
                            <Col cols={3} className='my-auto'>
                              <div className={`px-3 badge badge-soft-${table.table_form === 1 ? 'info' : 'secondary'} d-inline`}>
                                <i className={getTableShape(table.table_form).icon}></i>
                                <span className="ms-1">{getTableShape(table.table_form).name}</span>
                              </div>
                            </Col>
                            <Col cols={3} className='my-auto'>
                              <span>{table.nb_couvert} - {table.max_nb_couvert}</span>
                            </Col>
                            <Col cols={3} className='my-auto'>
                              <button onClick={() => toggleTable(el, table)} type="button" className="btn btn-sm btn-light text-nowrap me-2">
                                <i className="ri-edit-line align-bottom"></i>
                              </button>
                              <button onClick={() => toggleModal1(table)} type="button" className="btn btn-sm btn-light text-nowrap me-2">
                                <i className="ri-delete-bin-line align-bottom"></i>
                              </button>
                              <button onClick={() => dispatch(editTable({id: table.id, available: table.available ? 0 : 1 }))} type="button" className={`btn btn-sm btn-outline-${table.available ? 'success' : 'danger'} text-nowrap`}>
                                {table.available === 1 && <i className="ri-check-line align-bottom"></i>}
                                {!table.available && <i className="ri-close-line align-bottom"></i>}
                              </button>
                            </Col>
                          </Row>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              ))}
              {rooms.length === 0 && <span className="d-flex justify-content-center">{t('No rooms yet')},<Link className='ms-1' onClick={() => toggleRoomName()}> {t('Add room')}</Link></span>}
            </div>

              </CardBody>
            </Card>
  
          </Container>
        </div>
        <Modal
            isOpen={modal}
            toggle={() => { toggleModal() }}
            centered
            scrollable={true}
            size="lg"
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                {current ? t('Update room') : t('Add room')}
                </h5>
                <button
                type="button"
                onClick={() => { setModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ maxHeight: 400 }}>
                <Details info={current} shapes={shapes.filter(el => el.id)} />
            </div>
        </Modal>
        <Modal
            isOpen={roomNameModal}
            toggle={() => { toggleRoomName() }}
            centered
            scrollable={true}
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                {current ? t('Update room') : t('Add room')}
                </h5>
                <button
                type="button"
                onClick={() => { setroomNameModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Room name')}</Label>
                  <Input placeholder={t("Room name")} value={roomName} onChange={e => setRoomName(e.target.value)} type="text" name="name" />
              </FormGroup>
            </div>
            <div className="modal-footer" style={{ maxHeight: 400 }}>
              <div className="d-flex justify-content-end">
                <button onClick={() => createRoom()} className="btn btn-primary">{!current ? t('Add room') : t('Update room')}</button>
              </div>
            </div>
        </Modal>
        <Modal
            isOpen={tableModal}
            toggle={() => { toggleTable() }}
            centered
            scrollable={true}
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                {currentTable ? t('Update table') : t('Add table')}
                </h5>
                <button
                type="button"
                onClick={() => { setTableModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Table name')}</Label>
                  <Input placeholder={t("Table name")} value={tableName} onChange={e => setTableName(e.target.value)} type="text" name="name" />
              </FormGroup>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Table shape')}</Label>
                  <Input
                    type="select"
                    name="role"
                    id="roleSelect"
                    placeholder={t("Table shape")}
                    size="sm"
                    value={tableShape}
                    onChange={e => setTableShape(parseInt(e.target.value))}
                    >
                    {shapes.map(shape => (
                        <option key={shape.id} value={shape.id} selected={shape.id === currentTable?.id}>{shape.name}</option>
                    ))}
                    </Input>
              </FormGroup>
              <div className='d-flex'>
                <FormGroup className="flex-grow-1 me-2">
                    <Label for="table">{t('Covers')}</Label>
                    <Input placeholder={t("Max covers")} value={covers} onChange={e => setCovers(e.target.value)} type="text" name="name" />
                </FormGroup>
                <FormGroup className="flex-grow-1">
                    <Label for="table">{t('Max covers')}</Label>
                    <Input placeholder={t("Max covers")} value={maxCovers} onChange={e => setMaxCovers(e.target.value)} type="text" name="name" />
                </FormGroup>
              </div>
            </div>
            <div className="modal-footer" style={{ maxHeight: 400 }}>
              <div className="d-flex justify-content-end">
                <button onClick={() => createTable(currentTable)} className="btn btn-primary">{!currentTable ? 'Add' : 'Update'} table</button>
              </div>
            </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle={() => { toggleModal1() }}
          centered
          >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
              Delete table
              </h5>
              <button
                type="button"
                onClick={() => { setModal1(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
            <h5>{t('Do you want to delete')} <strong>{current?.name}</strong>?</h5>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button onClick={() => deleteTable(current.id)} type="submit" class="btn btn-danger px-4">{t('Yes')}</button>
            <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">{t('No')}</button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle={() => { toggleModal2() }}
          centered
          >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
              {t('Delete room')}
              </h5>
              <button
                type="button"
                onClick={() => { setModal2(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
            <h5>{t('Do you want to delete')} <strong>{current?.name}</strong>?</h5>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button onClick={() => deleteRoom(current.id)} type="submit" class="btn btn-danger px-4">{t('Yes')}</button>
            <button onClick={() => setModal2(false)} type="submit" class="btn btn-secondary px-4">{t('No')}</button>
          </div>
        </Modal>
      </React.Fragment>
    );
};

export default withRouter(withTranslation()(Table));