import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Details from "./details";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ElItem from "./ElItem";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addItem, editCategory, removeCategory } from "../../store/actions";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
const ItemType = "BOX";

const CatItem = ({ cat, index, moveCat, setCategoryName, addElRow, moveEl, setElementName, deleteElement }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [category, setCategory] = useState(null);
  const [catModal, setCatModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const [itemName, setItemName] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const [itemDescription, setItemDescription] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setCategory(cat.name)
  }, [cat])
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        // moveCat(draggedItem.index, index);
        // draggedItem.index = index;
      }
    },
    drop: (draggedItem) => {
      moveCat(draggedItem.index, index, true);
      draggedItem.index = index;
    },
  });

  const toggleCatModal = () => {
    setCatModal(!catModal)
  }

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const toggleItemModal = () => {
    setItemName(null);
    setItemPrice(null);
    setItemDescription(null);
    setItemModal(!itemModal)
  }

  const updateCategory = () => {
    dispatch(editCategory({ name: category, id: cat.id }))
    setCatModal(false)
  }

  const deleteCategory = () => {
    dispatch(removeCategory(cat.id))
    setDeleteModal(false)
  }

  const createItem = () => {
    dispatch(addItem({ id: cat.id, name: itemName, price: itemPrice, description: itemDescription }))
    setItemModal(false)
    setShowDetails(true)
  }
 
//   const moveEl = (dragIndex, hoverIndex) => {
//     const elements = categories
//     const draggedEl = elements[dragIndex];
//     const updatedEl = [...elements];
//     updatedEl.splice(dragIndex, 1);
//     updatedEl.splice(hoverIndex, 0, draggedEl);
//     setEls(updatedEl)
// };
  
  return (
    <div className="card">
        <CardHeader className="d-flex align-items-center shadow rounded">
            <span ref={(node) => drag(drop(node))} style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }} className="me-1">
                <b>☰</b>
            </span>
            <b className="flex-grow-1">{cat.name || 'New category'}</b>
            <button onClick={() => setShowDetails(!showDetails)} className="btn btn-sm btn-outline-secondary me-2 text-nowrap"><i className={`ri-arrow-${showDetails ? 'up' : 'down'}-s-line align-bottom`}></i></button>
            <button onClick={() => toggleCatModal()} className="btn btn-sm btn-outline-secondary me-2 text-nowrap"><i className="ri-edit-line align-bottom"></i></button>
            <button onClick={() => toggleItemModal()} className="btn btn-sm btn-outline-success me-2 text-nowrap"><i className="ri-add-line align-bottom"></i></button>
            <button disabled={cat?.elements?.length > 0} onClick={() => toggleDeleteModal()} className="btn btn-sm btn-outline-danger me-2 shadow text-nowrap"><i className="ri-delete-bin-line align-bottom"></i></button>
        </CardHeader>
        {showDetails && <CardBody>
            <DndProvider backend={HTML5Backend}>
                {cat?.elements?.map((el, idx) => (
                    <ElItem
                        key={idx}
                        el={el}
                        setElementName={setElementName}
                        deleteElement={deleteElement}
                        moveEl={moveEl}
                        index={idx}
                        catIndex={index}
                    />
                ))}
            </DndProvider>
            {cat?.elements?.length === 0 && <span className="d-flex justify-content-center">{t('This menu is empty')} <Link className="ms-1" onClick={() => toggleItemModal()}>{t('Add item')}</Link></span>}
        </CardBody>}
        <Modal
        isOpen={catModal}
        toggle={() => { toggleCatModal() }}
        centered
        >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
              {t('Edit category')}
              </h5>
              <button
                type="button"
                onClick={() => { setCatModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Category name')}</Label>
                  <Input placeholder={t("Room name")} value={category} onChange={e => setCategory(e.target.value)} type="text" name="name" />
              </FormGroup>
          </div>
          <div className="modal-footer d-flex justify-content-end">
              <button onClick={() => setCatModal(false)} type="submit" class="btn btn-secondary px-4">{t('Close')}</button>
              <button onClick={() => updateCategory()} type="submit" class="btn btn-primary px-4">{t('Update')}</button>
          </div>
      </Modal>

      <Modal
        isOpen={deleteModal}
        toggle={() => { toggleDeleteModal() }}
        centered
      >
        <div className="modal-header">
            <h5 className="modal-title mt-0">
              {t('Delete category')}
            </h5>
            <button
              type="button"
              onClick={() => { setDeleteModal(false) }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
          <h5>{t('Do you want to delete')} <strong>{cat?.name}</strong>?</h5>
        </div>
        <div className="modal-footer d-flex justify-content-center">
            <button onClick={() => deleteCategory()} type="submit" class="btn btn-danger px-4">{t('Yes')}</button>
            <button onClick={() => setDeleteModal(false)} type="submit" class="btn btn-secondary px-4">{t('No')}</button>
        </div>
      </Modal>

      <Modal
        isOpen={itemModal}
        toggle={() => { toggleItemModal() }}
        centered
        >
          <div className="modal-header">
              <h5 className="modal-title mt-0">
                {t('Add item')}
              </h5>
              <button
                type="button"
                onClick={() => { setItemModal(false) }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body" style={{ maxHeight: 400 }}>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Name')}</Label>
                  <Input placeholder={t("Name")} value={itemName} onChange={e => setItemName(e.target.value)} type="text" name="item-name" />
              </FormGroup>
              <FormGroup className="flex-grow-1">
                  <Label for="table">{t('Price')}</Label>
                  <Input placeholder={t("Price")} value={itemPrice} onChange={e => setItemPrice(e.target.value)} type="number" name="price" />
              </FormGroup>
              <FormGroup className="flex-grow-1">
                  <Label for="table">Description</Label>
                  <Input placeholder={`${t('Sauces, compliments etc')}...`} value={itemDescription} onChange={e => setItemDescription(e.target.value)} type="textarea" name="description" />
              </FormGroup>
          </div>
          <div className="modal-footer d-flex justify-content-end">
              <button onClick={() => setItemModal(false)} type="submit" class="btn btn-secondary px-4">{t('Close')}</button>
              <button onClick={() => createItem()} type="submit" class="btn btn-primary px-4">{t('Update')}</button>
          </div>
      </Modal>
    </div>
  )
}

export default withRouter(withTranslation()(CatItem));