import { EDIT_PACK, FETCH_PACKS, PACK_ERROR, PACK_SUCCESS, SET_PACKS } from "./actionTypes"

export const fetchPacks = data => {
  return {
    type: FETCH_PACKS,
    payload: data
  }
}

export const setPacks = data => {
  return {
    type: SET_PACKS,
    payload: data,
  }
}

export const editPack = data => {
  return {
    type: EDIT_PACK,
    payload: data,
  }
}

export const packSuccess = msg => {
  return {
    type: PACK_SUCCESS,
    payload: msg,
  }
}

export const packError = error => {
  return {
    type: PACK_ERROR,
    payload: error,
  }
}
