import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Maintenance from "../Pages/Maintenance-Page";
import UserProfile from "../Pages/Authentication/user-profile";
import Restaurant from "../Pages/Restaurant";
import Reservations from "../Pages/reservations";
import Configuration from "../Pages/Configuration";
import Table from "../Pages/Table";
import Menu from "../Pages/Menu";
import Invoice from '../Pages/Invoice';
import Promotion from "../Pages/Promotion";
import Images from "../Pages/Image";
import Packs from "../Pages/Packs";
import Contract from "../Pages/Contract";
import Rapports from "../Pages/Rapports";
import External from "../Pages/External";
import Calendar from "../Pages/Calendar";
import Details from "../Pages/Invoice/details";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/maintenance", component: <Maintenance /> },
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/resto-info", component: <Restaurant /> },
  { path: "/resto-reservation", component: <Reservations /> },
  { path: "/resto-config", component: <Configuration /> },
  { path: "/tables", component: <Table /> },
  { path: "/resto-menus", component: <Menu /> },
  { path: "/invoices", component: <Invoice /> },
  { path: "/invoices/details", component: <Details /> },
  { path: "/promotions", component: <Promotion /> },
  { path: "/images", component: <Images /> },
  { path: "/resto-pack", component: <Packs /> },
  { path: "/contracts", component: <Contract /> },
  { path: "/rapports", component: <Rapports /> },
  { path: "/external-integration", component: <External /> },
  { path: "/calendar", component: <Calendar /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

];

export { authProtectedRoutes, publicRoutes };
