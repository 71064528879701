import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_CATEGORY, ADD_ITEM, ADD_MENU, DELETE_CATEGORY, DELETE_ITEM, DELETE_MENU, EDIT_CATEGORY, EDIT_ITEM, EDIT_MENU, FETCH_MENU, FETCH_MENUS, MOVE_CATEGORY, MOVE_ITEM, MOVE_MENU } from "./actionTypes"
import { setMenus, setMenu, menuSuccess, menuError } from "./actions"

//Include Both Helper File with needed methods
import { deleteCategory, deleteItem, deleteMenu, getMenu, getMenus, postCategory, postItem, postMenu, putCategory, putCategoryOrder, putItem, putItemOrder, putMenu, putMenuOrder } from "../../helpers/backend_helper"


function* fetchMenus({ payload: data }) {
  try {
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* fetchMenu({ payload: data }) {
  try {
    const response = yield call(getMenu, data)
    yield put(setMenu(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* addMenu({ payload: data }) {
  try {
     yield call(postMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* editMenu({ payload: data }) {
  try {
     yield call(putMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* moveMenu({ payload: data }) {
  try {
     yield call(putMenuOrder, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* removeMenu({ payload: data }) {
  try {
     yield call(deleteMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* addCategory({ payload: data }) {
  try {
     yield call(postCategory, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* editCategory({ payload: data }) {
  try {
     yield call(putCategory, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* moveCategory({ payload: data }) {
  try {
     yield call(putCategoryOrder, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* removeCategory({ payload: data }) {
  try {
     yield call(deleteCategory, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* addItem({ payload: data }) {
  try {
     yield call(postItem, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* editItem({ payload: data }) {
  try {
     yield call(putItem, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* moveItem({ payload: data }) {
  try {
     yield call(putItemOrder, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* removeItem({ payload: data }) {
  try {
     yield call(deleteItem, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

export function* watchMenu() {
  yield takeEvery(FETCH_MENUS, fetchMenus)
  yield takeEvery(FETCH_MENU, fetchMenu)
  yield takeEvery(ADD_MENU, addMenu)
  yield takeEvery(EDIT_MENU, editMenu)
  yield takeEvery(DELETE_MENU, removeMenu)
  yield takeEvery(ADD_CATEGORY, addCategory)
  yield takeEvery(EDIT_CATEGORY, editCategory)
  yield takeEvery(DELETE_CATEGORY, removeCategory)
  yield takeEvery(ADD_ITEM, addItem)
  yield takeEvery(EDIT_ITEM, editItem)
  yield takeEvery(DELETE_ITEM, removeItem)

  yield takeEvery(MOVE_MENU, moveMenu)
  yield takeEvery(MOVE_CATEGORY, moveCategory)
  yield takeEvery(MOVE_ITEM, moveItem)

}

function* MenuSaga() {
  yield all([fork(watchMenu)])
}

export default MenuSaga;
