import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_ROOM, ADD_TABLE, DELETE_ROOM, DELETE_TABLE, EDIT_ROOM, EDIT_TABLE, FETCH_ROOM, FETCH_ROOMS, FETCH_TABLES } from "./actionTypes"
import { setTables, tableSuccess, tableError, setRooms, setRoom } from "./actions"

//Include Both Helper File with needed methods
import { deleteRoom, deleteTable, getRoom, getRooms, getTables, postRoom, postTable, putRoom, putTable } from "../../helpers/backend_helper"


function* fetchTables({ payload: data }) {
  try {
    const response = yield call(getTables, data)
    yield put(setTables(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* fetchRooms({ payload: data }) {
  try {
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* fetchRoom({ payload: id }) {
  try {
    const response = yield call(getRoom, id)
    yield put(setRoom(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* addRoom({ payload: data }) {
  try {
     yield call(postRoom, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* editRoom({ payload: data }) {
  try {
     yield call(putRoom, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* addTable({ payload: data }) {
  try {
     yield call(postTable, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* editTable({ payload: data }) {
  try {
     yield call(putTable, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* removeRoom({ payload: data }) {
  try {
     yield call(deleteRoom, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

function* removeTable({ payload: data }) {
  try {
     yield call(deleteTable, data)
    const response = yield call(getRooms, data)
    yield put(setRooms(response))
    yield put(tableSuccess(response))
  } catch (error) {
    yield put(tableError(error))
  }
}

export function* watchTable() {
  yield takeEvery(FETCH_TABLES, fetchTables)
  yield takeEvery(FETCH_ROOMS, fetchRooms)
  yield takeEvery(FETCH_ROOM, fetchRoom)
  yield takeEvery(ADD_ROOM, addRoom)
  yield takeEvery(EDIT_ROOM, editRoom)
  yield takeEvery(ADD_TABLE, addTable)
  yield takeEvery(EDIT_TABLE, editTable)
  yield takeEvery(DELETE_ROOM, removeRoom)
  yield takeEvery(DELETE_TABLE, removeTable)
}

function* TableSaga() {
  yield all([fork(watchTable)])
}

export default TableSaga;
