import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Input, Label, Modal, Row, Card, CardBody } from "reactstrap";
import { addRoom, editRoom, removeRoom } from "../../store/actions";
import logo from "../../assets/images/mm-dark.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Details = () => {
    const [method, setMethod] = useState('card')
    const [info, setInfo] = useState({});
    const invoices = useSelector(state => state.invoice.data);
    const invoiceRef = useRef(null);

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    useEffect(() => {
        const target = invoices.find(el => el.id === Number(id))
        setInfo(target)
    }, [id, invoices])

    const resolveStatusTitle = status => {
        if (status === 0) return t('Unpaid')
        if (status === 1) return t('Paid')
        if (status === 2) return t('Paid with MIAMS')
        return status
      }
    
      const resolveStatusBg = status => {
        if (status === 0) return '#fcb92c' // Unpaid
        if (status === 1) return '#0ac074' // Paid
        if (status === 2) return '#4aa3ff' // Paid with MIAMS
        return '#74788d'
      }

      const resolveDate = isoDate => {
        if (!isoDate) return null; // Handle invalid input
        const date = new Date(isoDate);
      
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
      
        return `${day}-${month}-${year}`;
      }

      const generatePDF = () => {
        if (!invoiceRef.current) {
          console.error("Invoice content not found!");
          return;
        }

        const elementsToHide = invoiceRef.current.querySelectorAll(".exclude-from-pdf");
        elementsToHide.forEach((el) => (el.style.display = "none"));
            
        html2canvas(invoiceRef.current).then((canvas) => {
          const pdf = new jsPDF();
          const imgData = canvas.toDataURL("image/png");
      
          // Set dimensions for the image in the PDF
          const imgWidth = 190; // Width in mm for A4 page
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
          pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight); // Add image to PDF
          pdf.save(`invoice_${info.id}.pdf`); // Save the PDF

          const elementsToHide = invoiceRef.current.querySelectorAll(".exclude-from-pdf");
          elementsToHide.forEach((el) => (el.style.display = ""));

        }).catch((error) => {
          console.error("Error generating PDF:", error);
        
          // Ensure elements are restored even if an error occurs
          const elementsToHide = invoiceRef.current.querySelectorAll(".exclude-from-pdf");
          elementsToHide.forEach((el) => (el.style.display = ""));
        });;
      };
      

    return (
        <div className="page-content">
          <Container fluid={true}>
            {/* <Breadcrumbs title={t("My restaurant")} breadcrumbItem={t("Invoices")} /> */}
                <Card>
                    <CardBody>
                        <div ref={invoiceRef}>
                        <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                                <div>
                                    <img
                                        src={logo}
                                        alt=""
                                        height="48"
                                    />
                                    <h4 className="my-4">{t('Invoice')} #{info?.id}</h4>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-end">
                                        <span className="d-inline-block badge px-3 py-2 mb-2" style={{ backgroundColor: resolveStatusBg(info?.status) }}> {resolveStatusTitle(info?.status)}</span>
                                    </div>
                                    {info?.status === 0 && <div className="d-flex justify-content-end">
                                        <span className="d-block px-2 mb-2 text-danger">{t('Due date')}: {resolveDate(info?.due_date)}</span>
                                    </div>}
                                    <div className="d-flex justify-content-end">
                                        <button className="exclude-from-pdf btn btn-sm btn-primary shadow text-nowrap px-3">
                                            {t('Pay now')}
                                        </button>
                                    </div>
                                </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2 pt-3">
                                <div style={{ minHeight: 270 }}>
                                    <h5>{t('Invoiced to')}:</h5>
                                    <span className="d-block mb-2">{info?.t_resto?.name}</span>
                                    <span className="d-block mb-2">{info?.t_resto?.t_resto_data?.find(el => el.data_key === 'owner_firstname')?.data_val} {info?.t_resto?.t_resto_data?.find(el => el.data_key === 'owner_lastname')?.data_val}</span>
                                    <span className="d-block mb-2">{info?.t_resto?.t_address.line1}</span>
                                    {info?.t_resto?.t_address.line2 && <span className="d-block mb-2">{info?.t_resto?.t_address.line2}</span>}
                                    <span className="d-block mb-2">{info?.t_resto?.t_address.zipcode}</span>
                                    <span className="d-block mb-2">{info?.t_resto?.t_address.city}</span>
                                    <h5>Invoice date:</h5>
                                    <span>{resolveDate(info?.invoice_date)}</span>
                                </div>
                                <div style={{ minHeight: 270 }}>
                                    <h5>{t('Payed to')}:</h5>
                                    <span className="d-block mb-1">STE MEDZEY</span>
                                    <span className="d-block">{t('Appartement 301 Bloc D Yassmine Residence')}</span>
                                    <span className="d-block mb-2">{t('3rd floor Kadi Ayad str')}</span>
                                    <span className="d-block mb-2">80000</span>
                                    <span className="d-block mb-2">Agadir</span>
                                    <span className="d-block mb-2">ICE: 002873930000010</span>
                                    <h5>{t('Payment method')}:</h5>
                                    <select className="form-control">
                                        {/* <option value={null} selected={!method}>Select method</option> */}
                                        <option value="card" selected={method === 'card'}>Card</option>
                                        <option value="cash" selected={method === 'cash'}>Cash</option>
                                    </select>
                                </div>
                        </div>
                            {/* <h4>{t('Invoice items')}</h4> */}
                            <div className="row">
                                    <div className="row">
                                        <div className="col-6">
                                        <h5>{t('Pack')}</h5>
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                        <h5>Total</h5>
                                        </div>
                                    </div>
                            </div>
                            <div className="px-4 py-2 my-2 border border-2 align-items-center">
                                <div className="row">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="d-block mb-0">{info?.val_pack  === 0 ? 'Free' : 'VIP1'}</p>
                                            </div>
                                            <div className="col-3">
                                            </div>
                                            <div className="col-3">
                                                <p className="d-block mb-0">{info?.val_pack} DHs</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <h5>{t('Online reservations')}</h5>
                            <div className="px-4 py-2 border border-2">
                            <div className="row">
                                <div className="row mb-2">
                                    <div className="col-6 border-bottom py-2">
                                        <b className="d-block">Source</b>
                                    </div>
                                    <div className="col-3 border-bottom py-2">
                                        <b className="d-block">{t('Covers')}</b>
                                    </div>
                                    <div className="col-3 border-bottom py-2">
                                        {/* <b className="d-block">Total</b> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {[
                                    { id: 2, label: 'Mymeal web', value: info?.val_m_web },
                                    { id: 3, label: 'Mymeal app', value: info?.val_m_app },
                                    { id: 4, label: 'Instagram', value: info?.val_insta },
                                    { id: 5, label: 'Whatsapp', value: info?.val_whats },
                                    { id: 6, label: 'Snapshot', value: info?.val_snap },
                                    { id: 7, label: 'Tiktok', value: info?.val_tikt },
                                    { id: 8, label: 'Walkin', value: info?.val_walkin },
                                    { id: 9, label: 'Butlers', value: info?.val_butlers },
                                    // Uncomment if "Others" is needed
                                    // { label: 'Others', value: info?.val_others },
                                ].filter(el => el.value).map((item, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-6 border-bottom">
                                            <p className="d-block">{item.label}</p>
                                        </div>
                                        <div className="col-3 border-bottom">
                                            <p className="d-block">{item.id === 9 ? '---' : item.value / 10}</p>
                                            <div>

                                            </div>
                                        </div>
                                        <div className="col-3 border-bottom">
                                            <p className="d-block">{item.value} DHs</p>
                                        </div>
                                    </div>
                                    ))}
                            </div>
                            <div className="row">
                                {[
                                    { id: 1, label: t('Subtotal'), value: info?.total - (info?.total * 0.2) },
                                    { id: 2, label: '20% TVA', value: info?.total * 0.2 },
                                    { id: 3, label: 'Total', value: info?.total },
                                ].map((item, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-6 border-bottom">
                                        </div>
                                        <div className="col-3 border-bottom">
                                            <b className="d-block">{item.label}</b>
                                            <div>

                                            </div>
                                        </div>
                                        <div className="col-3 border-bottom">
                                            <b className="d-block">{item.value?.toFixed(2)} DHs</b>
                                        </div>
                                    </div>
                                    ))}
                            </div>
                            </div>

                        <div className="row d-flex justify-content-between mt-4 mx-1 border border-2 p-2">
                                <div className="col-4">
                                    <b className="d-block mb-2">{t('Transaction date')}</b>
                                    <span>{info?.payed}</span>
                                </div>
                                <div className="col-2">
                                    <b className="d-block mb-2">{t('Gateway')}</b>
                                    <span>{info?.gateway}</span>
                                </div>
                                <div className="col-2">
                                    <b className="d-block mb-2">{t('Method')}</b>
                                    <span>{info?.transaction}</span>
                                </div>
                                <div className="col-auto">
                                    <b className="d-block mb-2">{t('Amount')}</b>
                                    <span>{info?.payed_amount}</span>
                                </div>
                                {!info?.payed && <div className="col-12 d-flex justify-content-center mt-2">
                                    <p className="d-block mb-2">{t('No transaction found')}</p>
                                </div>}
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-4 py-2">
                            <button onClick={() => generatePDF()} className="exclude-from-pdf btn btn-sm btn-primary me-2 shadow text-nowrap px-3">
                                {t('Download')}
                            </button>
                        </div>

                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default withRouter(withTranslation()(Details));